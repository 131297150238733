import React from 'react';
import { styled } from 'styled-components';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

interface RowRadioGroupProps {
  value: string;
  labels: string[];
  onChange?: (newValue: string) => void;
};

export const RowRadioGroup: React.FC<RowRadioGroupProps> = ({
  value,
  labels,
  onChange,
}) => {

  const radios = labels.map((label, index) => {
    return (
      <FormControlLabel key={index} value={label} control={<Radio />} label={label} />
    );
  });

  return (
    <DivRowRadioGroup>
      <FormControl>
        <RadioGroup
          row
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {onChange?.(e.target.value)}}
        >
          {radios}
        </RadioGroup>
      </FormControl>
    </DivRowRadioGroup>
  );
};

const DivRowRadioGroup = styled.div`
`;