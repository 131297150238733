import React from 'react';
import { styled } from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

interface BaseDialogProps {
  open?: boolean;
  width?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  onClose?: () => void;
  titleChildren?: React.ReactNode;
  contentChildren?: React.ReactNode;
  topActionChildren?: React.ReactNode;
  bottomActionChildren?: React.ReactNode;
};

export const BaseDialog: React.FC<BaseDialogProps> = ({
  open,
  width = 'md',
  onClose,
  titleChildren,
  contentChildren,
  topActionChildren,
  bottomActionChildren,
}) => {
  return (
    <DivBaseDialog>
      <Dialog
        open={open === undefined ? false : open}
        onClose={onClose}
        fullWidth
        maxWidth={width}
      >
        <DialogTitle>
          {titleChildren}
        </DialogTitle>
        <DivTopActions>
          <DialogActions>
            {topActionChildren}
          </DialogActions>
        </DivTopActions>
        <DialogContent>
          {contentChildren}
        </DialogContent>
        <DialogActions>
          {bottomActionChildren}
        </DialogActions>
      </Dialog>
    </DivBaseDialog>
  );
};

const DivBaseDialog = styled.div`
`;

const DivTopActions = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;
