/**
 * @file src/state/ducks/advice.ts
 * @description アドバイスに関するRedux Duck
 * @module Ducks/Advice
 * @category State
 * @require module:axios
 * @require module:@reduxjs/toolkit
 * @require module:../../types
 * @require module:../../config
 * @exports adviceSlice
 * @exports getAdvice
 * @exports default
 * @exports adviceState
 * @exports adviceSlice
 * @exports getAdvice
 * @exports default
 */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { Advice } from '../../types';

import { api } from '../../config';

export interface adviceState {
	asyncStatus?: string;
	advice?: Advice;
}

const initialState: adviceState = {
	asyncStatus: undefined,
	advice: undefined,
};

export const adviceSlice = createSlice({
	name: 'advice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAdvice.fulfilled, (state, action) => {
				state.advice = action.payload;
				state.asyncStatus = 'FULFILLED_GET_NOTIFICATIONS';
			})
			.addCase(getAdvice.pending, (state, action) => {
				state.asyncStatus = 'PENDING_GET_NOTIFICATIONS';
			})
			.addCase(getAdvice.rejected, (state, action) => {
				state.asyncStatus = 'REJECTED_GET_NOTIFICATIONS';
			});
	},
});

/**
 * ChatGPTによるアドバイスを取得する非同期関数
 *
 * @param {string} userId - ユーザーID
 * @returns {Promise<Advice>} ChatGPTによるアドバイス
 * @example
 * dispatch(getAdvice(userId));
 */
export const getAdvice = createAsyncThunk('advice/getAdvice', async (userId: string) => {
	const res = await axios.get(`${api}/advices/${userId}`);
	return res.data;
});

export default adviceSlice.reducer;
