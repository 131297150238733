import React from 'react';
import { styled } from 'styled-components';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { DialogContentText } from '@mui/material';

import { Notification } from '../../../types';
import { BaseDialog } from '../../molecules/BaseDialog/BaseDialog';


interface NotificationShowDialogProps {
  open?: boolean;
  notification?: Notification;
  onClose?: () => void;
};

export const NotificationShowDialog: React.FC<NotificationShowDialogProps> = ({
  open,
  notification,
  onClose,
}) => {
  return (
    <DivNotificationShowDialog>
      <BaseDialog
        open={open}
        width="xs"
        contentChildren={
          <DialogContentText
            sx={{ 
              wordBreak: 'break-word',
            }}
          >
            {notification?.description}
          </DialogContentText>
        }
        onClose={onClose}
        titleChildren={notification?.title}
        topActionChildren={
          <IconButton
            onClick={onClose}
          >
            <Close />
          </IconButton>
        }
      />
    </DivNotificationShowDialog>
  );
};

const DivNotificationShowDialog = styled.div`
  display: 'flex';
`;