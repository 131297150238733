import React from 'react';
import { styled } from 'styled-components';
import { Button } from '@mui/material';
import { DialogContentText } from '@mui/material';
import { Divider } from '@mui/material';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

import { Community, Role } from '../../../types';
import { BaseDialog } from '../../molecules/BaseDialog/BaseDialog';
import { SimpleTextField } from '../../atoms/SimpleTextField/SimpleTextField';

interface CommunityJoinDialogProps {
	open?: boolean;
	initialCommunity?: Community;
	initialRole?: Role;
	onClose?: () => void;
	onSubmit?: (community: Community, role: Role) => void;
}

const defaultCommunity: Community = {
	id: '',
	name: '',
	description: '',
	members: [],
};

const defaultRole: Role = {
	id: '',
	userId: '',
	communityId: '',
	name: '',
	description: '',
};

export const CommunityJoinDialog: React.FC<CommunityJoinDialogProps> = ({
	open,
	initialCommunity = defaultCommunity,
	initialRole = defaultRole,
	onClose,
	onSubmit,
}) => {
	const [community, setCommunity] = React.useState<Community>(initialCommunity);
	const [role, setRole] = React.useState<Role>(initialRole);

	return (
		<DivCommunityJoinDialog>
			<BaseDialog
				open={open}
				width="xs"
				topActionChildren={
					<IconButton onClick={onClose}>
						<Close />
					</IconButton>
				}
				bottomActionChildren={
					<DivContentChildren>
						<Button onClick={() => onSubmit && onSubmit(community, role)}>参加</Button>
					</DivContentChildren>
				}
				contentChildren={
					<DivContentChildren>
						<DialogContentText sx={{ fontWeight: 'bold' }}>
							コミュニティのメンバーから「コミュニティID」を教えてもらい、入力してください。
						</DialogContentText>
						<SimpleTextField
							value={community.id}
							label="コミュニティID"
							width={'24rem'}
							helperText="ハイフン(-)を含む36文字の英数字 (例: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)"
							onChange={(communityId: string) => {
								setCommunity({ ...community, id: communityId });
							}}
						/>
						<Divider />
						<DivBlankBox />
						<DialogContentText
							sx={{
								fontWeight: 'bold',
							}}
						>
							コミュニティの中でのあなたの役割について教えてください。
						</DialogContentText>
						<SimpleTextField
							value={role.name}
							label="あなたの役割"
							helperText="1文字以上の文字列"
							width={'12rem'}
							onChange={(roleName: string) => {
								setRole({ ...role, name: roleName });
							}}
						/>
						<SimpleTextField
							value={role.description}
							label="役割の説明"
							multiline={true}
							onChange={(roleDescription: string) => {
								setRole({ ...role, description: roleDescription });
							}}
						/>
					</DivContentChildren>
				}
				onClose={onClose}
				titleChildren="コミュニティに参加する"
			/>
		</DivCommunityJoinDialog>
	);
};

const DivCommunityJoinDialog = styled.div`
	display: 'flex';
`;

const DivContentChildren = styled.div`
	display: 'flex';
`;

const DivBlankBox = styled.div`
	height: 1rem;
`;
