import React from 'react';
import { styled } from 'styled-components';
import { Dayjs } from 'dayjs';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';


interface TimePickersProps {
  value?: Dayjs;
  label?: string;
  onChange?: (newValue: Dayjs | null) => void;
};

export const TimePickers: React.FC<TimePickersProps> = ({
  value,
  label,
  onChange,
}) => {
  return (
    <DivTimePickers>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DivTimePickerGroup>
          <DivTimePickerComponent>
            <DatePicker
              label={label}
              value={value}
              onChange={onChange}
            />
          </DivTimePickerComponent>
          <DivTimePickerComponent>
            <TimePicker
              value={value}
              onChange={onChange}
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
              }}
            />
          </DivTimePickerComponent>
        </DivTimePickerGroup>
      </LocalizationProvider>
    </DivTimePickers>
  );
};

const DivTimePickers = styled.div`
`;

const DivTimePickerGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px;
`;

const DivTimePickerComponent = styled.div`
`;