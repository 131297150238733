import React from 'react';
import { styled } from 'styled-components';
import { Button } from '@mui/material';
import { IconButton } from '@mui/material';
import { Menu } from '@mui/material';

interface BaseMenuProps {
  ButtonContentChildren?: React.ReactNode;
  MenuItemChildren?: React.ReactNode;
  isIconButton?: boolean;
};

export const BaseMenu: React.FC<BaseMenuProps> = ({
  ButtonContentChildren,
  MenuItemChildren,
  isIconButton,
}) => {

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  return (
    <DivBaseMenu>
      {/* <Button
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget);
          setOpen(true);
        }}
      >
        {ButtonContentChildren}
      </Button> */}
      {
        isIconButton ? (
          <IconButton
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              setAnchorEl(event.currentTarget);
              setOpen(true);
            }}
          >
            {ButtonContentChildren}
          </IconButton>
        ) : (
          <Button
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              setAnchorEl(event.currentTarget);
              setOpen(true);
            }}
          >
            {ButtonContentChildren}
          </Button>
        )
      }
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={() =>{setOpen(false)}}
      >
        {MenuItemChildren}
      </Menu>
    </DivBaseMenu>
  );
};

const DivBaseMenu = styled.div`
`;