

/**
 * @file config.ts
 * @description アプリケーションの設定ファイル
 * @module Config
 * @category Config
 * @require module:react
 * @exports api
 * @exports maxSnack
 */

/**
  使用するAPIのURLを定義

  @remarks
  ./state/ducksの各ファイルで使用
*/
export const api: string = process.env.REACT_APP_API_URL as string;

/**
  Snackbarの最大表示数

  @remarks
  ./App.tsxで使用
  この数を超えると古いSnackbarが消える
*/
export const maxSnack: number = 3;

/**
 * 気分の表示ページ(0始まり)
 *
 * @remarks
 * ./state/ducks/moods.tsで使用
 */
export const moodPage: number = 0;

/**
 * 気分の1ページあたりの表示数
 *
 * @remarks
 * ./state/ducks/moods.tsで使用
 */
export const moodLimit: number = 7;

/**
 * イベントの表示ページ(0始まり)
 *
 * @remarks
 * ./state/ducks/notification.tsで使用
 */
export const notificationPage: number = 0;

/**
 * イベントの1ページあたりの表示数
 *
 * @remarks
 * ./state/ducks/notification.tsで使用
 */
export const notificationLimit: number = 5;
