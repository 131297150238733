/**
 * @file store.ts
 * @description Reduxのstoreを作成するファイル
 * @module Store
 * @category State
 * @require module:reduxjs/toolkit
 * @require module:ducks/users
 * @require module:ducks/communities
 * @require module:ducks/notifications
 * @require module:ducks/moods
 * @require module:ducks/events
 * @require module:ducks/advice
 * @exports store
 * @exports RootState
 * @exports AppDispatch
 */

import { configureStore } from '@reduxjs/toolkit';

import usersReducer from './ducks/users';
import communitiesReducer from './ducks/communities';
import notificationsReducer from './ducks/notifications';
import moodsReducer from './ducks/moods';
import eventsReducer from './ducks/events';
import adviceReducer from './ducks/advice';

/**
 * Reduxのstoreを作成
 *
 * @const store
 * @type {Store}
 */
export const store = configureStore({
	reducer: {
		users: usersReducer,
		communities: communitiesReducer,
		notifications: notificationsReducer,
		moods: moodsReducer,
		events: eventsReducer,
		advice: adviceReducer,
	},
});

/**
 * RootStateの型定義
 *
 * @remarks
 * storeのstate全体の型を定義
 * ./components/pages/CommonsCalendar/CommonsCalendar.tsxにおいて使用
 */
export type RootState = ReturnType<typeof store.getState>;

/**
 * AppDispatchの型定義
 *
 * @remarks
 * storeのdispatch関数の型を定義
 * ./components/pages/CommonsCalendar/CommonsCalendar.tsxにおいて使用
 */
export type AppDispatch = typeof store.dispatch;
