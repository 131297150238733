import React, { useEffect } from 'react';
import { styled } from 'styled-components';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Output } from '@mui/icons-material';
import { Button } from '@mui/material';
import { DialogContentText } from '@mui/material';
import { Divider } from '@mui/material';

import { User, Community, Role } from '../../../types';
import { BaseDialog } from '../../molecules/BaseDialog/BaseDialog';
import { CharIcon } from '../../atoms/CharIcon/CharIcon';
import { SimpleTextField } from '../../atoms/SimpleTextField/SimpleTextField';

interface CommunityShowDialogProps {
	open?: boolean;
	user?: User;
	community?: Community;
	onClose?: () => void;
	onLeave?: () => void;
	onDelete?: () => void;
	onSubmitRole?: (role: Role) => void;
}

const defaultRole: Role = {
	id: '',
	userId: '',
	communityId: '',
	name: '',
	description: '',
};

export const CommunityShowDialog: React.FC<CommunityShowDialogProps> = ({
	open,
	user,
	community,
	onClose,
	onLeave,
	onDelete,
	onSubmitRole,
}) => {
	const [initialRole, setInitialRole] = React.useState<Role>(defaultRole);
	const [role, setRole] = React.useState<Role>(defaultRole);

	useEffect(() => {
		// find user's role in community
		if (user && community) {
			if (community.members) {
				const member = community.members.find((member) => member.user.id === user.id);
				if (member) {
					setInitialRole(member.role);
					setRole(member.role);
				}
			}
		}
	}, [user, community]);

	return (
		<DivCommunityShowDialog>
			<BaseDialog
				open={open}
				width="xs"
				topActionChildren={
					<DivTopActionChildren>
						<IconButton onClick={onLeave}>
							<Output />
						</IconButton>
						<IconButton onClick={onClose}>
							<Close />
						</IconButton>
					</DivTopActionChildren>
				}
				bottomActionChildren={
					<Button onClick={() => onSubmitRole && onSubmitRole(role)}>役割の変更</Button>
				}
				contentChildren={
					<DivContentChildren>
						<DivFlexColumn>
							<DivFlexRow>
								<CharIcon char={community?.name} />
								<DivFlexColumn>
									<DialogContentText
										sx={{
											fontWeight: 'bold',
											fontSize: '1.5rem',
											verticalAlign: 'center',
											marginLeft: '1rem',
										}}
									>
										{community?.name}
									</DialogContentText>
									<DialogContentText
										sx={{
											fontSize: '0.8rem',
											verticalAlign: 'center',
											marginLeft: '1rem',
										}}
									>
										コミュニティID: {community?.id}
									</DialogContentText>
								</DivFlexColumn>
							</DivFlexRow>
							<DivBlankBox />
							<DialogContentText
								sx={{
									wordBreak: 'break-word',
								}}
							>
								{community?.description}
							</DialogContentText>
						</DivFlexColumn>
						<DivBlankBox />
						<Divider />
						<DivBlankBox />
						<DialogContentText
							sx={{
								fontWeight: 'bold',
							}}
						>
							あなたの役割: {initialRole.name}
						</DialogContentText>
						<DivBlankBox />
						<DialogContentText
							sx={{
								wordBreak: 'break-word',
							}}
						>
							{initialRole.description}
						</DialogContentText>
						<DivBlankBox />
						<Divider />
						<DivBlankBox />
						<DialogContentText
							sx={{
								fontWeight: 'bold',
							}}
						>
							役割を変更する
						</DialogContentText>
						<SimpleTextField
							value={role.name}
							label="あなたの新しい役割"
							width={'12rem'}
							helperText="1文字以上の文字列"
							onChange={(roleName: string) => {
								setRole({ ...role, name: roleName });
							}}
						/>
						<SimpleTextField
							value={role.description}
							label="役割の説明"
							multiline={true}
							onChange={(roleDescription: string) => {
								setRole({ ...role, description: roleDescription });
							}}
						/>
					</DivContentChildren>
				}
				onClose={onClose}
				titleChildren={community?.name}
			/>
		</DivCommunityShowDialog>
	);
};

const DivCommunityShowDialog = styled.div`
	display: 'flex';
`;

const DivContentChildren = styled.div``;

const DivFlexRow = styled.div`
	display: flex;
	flex-direction: row;
`;

const DivFlexColumn = styled.div`
	display: flex;
	flex-direction: column;
`;

const DivBlankBox = styled.div`
	height: 1rem;
`;

const DivTopActionChildren = styled.div`
	display: flex;
`;
