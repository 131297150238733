import React, { useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { styled } from 'styled-components';
import { Button } from '@mui/material';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Delete } from '@mui/icons-material';
import { EventInput } from '@fullcalendar/core';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { v4 as uuidv4 } from 'uuid';
import { DialogContentText } from '@mui/material';
import { Divider } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { User, Community } from '../../../types';
import { BaseDialog } from '../../molecules/BaseDialog/BaseDialog';
import { SimpleTextField } from '../../atoms/SimpleTextField/SimpleTextField';
import { TimePickers } from '../../molecules/TimePickers/TimePickers';
import { ColorPicker } from '../../molecules/ColorPicker/ColorPicker';
import { RowCheckboxes } from '../../molecules/RowCheckboxes/RowCheckboxes';
import { SingleCheckbox } from '../../atoms/SingleCheckbox/SingleCheckbox';
import { RowRadioGroup } from '../../molecules/RowRadioGroup/RowRadioGroup';
import { DivSwitchFlexbox } from '../../atoms/DivSwitchFlexbox/DivSwitchFlexbox';


interface EventDialogProps {
  open?: boolean;
  user?: User;
  community?: Community;
  initialEvent?: EventInput;
  isUpdate?: boolean;
  onClose?: () => void;
  onSubmit?: (event: EventInput) => void;
  onDelete?: () => void;
};

const defaultEvent: EventInput = {
  id: '',
  title: '',
  start: dayjs().toDate(),
  end: dayjs().add(1, 'h').toDate(),
  allDay: false,
  daysOfWeek: [],
  backgroundColor: '#F38D8D',
  extendedProps: {
    user: {
      id: '',
      name: '',
      email: '',
      img: '',
      description: '',
    },
    community: {
      id: '',
      name: '',
      img: '',
      description: '',
    },
    state: '対応不可',
    routine: false,
    description: '',
    public: false,
    member: [],  // TODO
  },
};

export const EventDialog: React.FC<EventDialogProps> = ({
  open,
  user,
  community,
  initialEvent = defaultEvent,
  isUpdate = false,
  onClose,
  onSubmit,
  onDelete,
}) => {

  const [event, setEvent] = React.useState<EventInput>(initialEvent);

  useEffect(() => {
    setEvent(initialEvent);
  }, [open]);

  return (
    <DivEventDialog>
      <BaseDialog
        open={open}
        width="xs"
        topActionChildren={
          <DivTopActionChildren>
            <DivSwitchFlexbox open={isUpdate}>
              <IconButton
                onClick={() => {onDelete && onDelete()}}
              >
                <Delete />
              </IconButton>
            </DivSwitchFlexbox>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </DivTopActionChildren>
        }
        contentChildren={
          <DivContentChildren>
            <DialogContentText
              sx = {{ fontWeight: 'bold' }}
            >
              この予定について
            </DialogContentText>
            <DivBlankBox />
            <SimpleTextField
              label="タイトル"
              value={event.title}
              width="16rem"
              helperText='1文字以上の文字列'
              onChange={(title: string) => {
                setEvent({ ...event, 'title': title });
              }}
            />
            <DivSwitchFlexbox open={!event.allDay}>
              <DivFlexColumn>
                <TimePickers
                  value={event.start ? dayjs(event.start.toString()) : undefined}
                  label="開始時間"
                  onChange={(start: Dayjs | null) => {
                    setEvent({ ...event, 'start': start?.toDate() });
                  }}
                />
                <DivArrow>
                  <ArrowDownwardIcon />
                </DivArrow>
                <TimePickers
                  value={event.end ? dayjs(event.end.toString()) : undefined}
                  label="終了時間"
                  onChange={(end: Dayjs | null) => {
                    setEvent({ ...event, 'end': end?.toDate() });
                  }}
                />
              </DivFlexColumn>
            </DivSwitchFlexbox>
            <SingleCheckbox
              label="一日中の予定"
              checked={event.allDay}
              onClick={() => {
                setEvent({ ...event, 'allDay': !event.allDay });
              }}
            />

            <SimpleTextField
              label="イベントの詳細"
              value={event.extendedProps?.description}
              multiline={true}
              width="100%"
              onChange={(description: string) => {
                setEvent({
                  ...event,
                  'extendedProps': { ...event.extendedProps, description },
                });
              }}
            />

            <DivBlankBox />
            <Divider />
            <DivBlankBox />

            <DialogContentText
              sx = {{ fontWeight: 'bold' }}
            >
              繰り返し設定
            </DialogContentText>

            <SingleCheckbox
              label="この予定を繰り返す"
              checked={event.extendedProps?.routine}
              onClick={() => {
                setEvent({
                  ...event,
                  'extendedProps': {
                    ...event.extendedProps,
                    routine: !event.extendedProps?.routine,
                  },
                });
              }}
            />
            <DivSwitchFlexbox open={event.extendedProps?.routine}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DivFlexColumn>
                  <RowCheckboxes
                    values={event.daysOfWeek}
                    labels={['日', '月', '火', '水', '木', '金', '土']}
                    onChange={(daysOfWeek: number[]) => {
                      setEvent({ ...event, 'daysOfWeek': daysOfWeek });
                    }}
                  />
                </DivFlexColumn>
              </LocalizationProvider>
            </DivSwitchFlexbox>

            <DivBlankBox />
            <Divider />
            <DivBlankBox />

            <DialogContentText
              sx = {{ fontWeight: 'bold' }}
            >
              この予定の忙しさは？
            </DialogContentText>
            <RowRadioGroup
              labels={[
                'ごめんなさい、今は難しい...',
                '通話などは可能！',
                '直接対応可能！',
              ]}
              value={event.extendedProps?.state}
              onChange={(state: string) => {
                setEvent({
                  ...event,
                  extendedProps: { ...event.extendedProps, state },
                });
              }}
            />

            <DivBlankBox />
            <Divider />
            <DivBlankBox />

            <DialogContentText
              sx = {{ fontWeight: 'bold' }}
            >
              予定の公開設定
            </DialogContentText>
            <SingleCheckbox
              label={`予定をコミュニティ「${community?.name}」に公開する`}
              checked={event.extendedProps?.public}
              onClick={() => {
                setEvent({
                  ...event,
                  'extendedProps': {
                    ...event.extendedProps,
                    public: !event.extendedProps?.public,
                  },
                });
              }}
            />

            <DivBlankBox />
            <Divider />
            <DivBlankBox />

            <DialogContentText
              sx = {{ fontWeight: 'bold' }}
            >
              予定の見た目
            </DialogContentText>
            <DivBlankBox />
            <ColorPicker
              color={event.backgroundColor}
              onChange={(color: string) => {
                setEvent({ ...event, 'backgroundColor': color });
              }}
            />
          </DivContentChildren>
        }
        bottomActionChildren={
          <Button
            onClick={() => {
              let newEvent = { ...event};
              if (!newEvent.extendedProps?.routine) {
                delete newEvent.daysOfWeek;
              }
              newEvent = {
                ...newEvent,
                id: newEvent.id || uuidv4(),
                extendedProps: {
                  ...newEvent.extendedProps,
                  userId: user?.id,  // TODO: ユーザーID以外の全ての情報を入力できるのが望ましい
                  communityId: community?.id,  // TODO: コミュニティID以外の全ての情報を入力できるのが望ましい
                },
              };
              onSubmit && onSubmit(newEvent);
            }}
          >
            {isUpdate ? '更新' : '作成'}
          </Button>
        }
        titleChildren={isUpdate ? '予定を編集' : '予定を作成'}
      />
    </DivEventDialog>
  );
};

const DivEventDialog = styled.div`
  display: 'flex';
`;

const DivBlankBox = styled.div`
  height: 1rem;
`;

const DivFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const DivArrow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DivContentChildren = styled.div`
`;

const DivTopActionChildren = styled.div`
  display: flex;
`;
