import React from 'react';
import { styled } from 'styled-components';
import { Card, CardActionArea } from '@mui/material';

interface BaseCardProps {
  onClick?: () => void;
  width?: string;
  height?: string;
  headerChildren?: React.ReactNode;
};

export const BaseCard: React.FC<BaseCardProps> = ({
  onClick,
  width = "24rem",
  height = "6rem",
  headerChildren,
}) => {
  return (
    <DivBaseCard
      width={width}
      height={height}
    >
      <Card
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <CardActionArea
          onClick={onClick}
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          {headerChildren}
        </CardActionArea>
      </Card>
    </DivBaseCard>
  );
};

const DivBaseCard = styled.div<{ width: string, height: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;