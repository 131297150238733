import React from 'react';
import { Avatar } from '@mui/material';


interface CharIconProps {
  char?: string;
  color?: string;
  onClick?: () => void;
};

export const CharIcon: React.FC<CharIconProps> = ({
  char = '',
  color = '#F38D8D',
  onClick,
}) => {
  return (
    <Avatar
      style={{backgroundColor: color}}
      onClick={onClick}
    >
      { char[0] }
    </Avatar>
  );
};
