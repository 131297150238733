/**
 * @file src/state/ducks/moods.ts
 * @description 気分に関するRedux Duck
 * @module Ducks/Moods
 * @category State
 * @require module:axios
 * @require module:@reduxjs/toolkit
 * @require module:dayjs
 * @require module:../../types
 * @require module:../../config
 * @exports moodState
 * @exports moodSlice
 * @exports getMoods
 * @exports postMood
 * @exports default
 */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import dayjs from 'dayjs';

import { Mood, User } from '../../types';
import { moodPage, moodLimit } from '../../config';

import { api } from '../../config';

export interface moodState {
	asyncStatus?: string;
	moods?: Mood[];
	askMood?: boolean;
}

const initialState: moodState = {
	asyncStatus: undefined,
	moods: undefined,
	askMood: false,
};

export const moodSlice = createSlice({
	name: 'mood',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(postMood.fulfilled, (state, action) => {
				state.moods = action.payload;
				state.askMood = false;
				state.asyncStatus = 'FULFILLED_POST_MOOD';
			})
			.addCase(postMood.pending, (state, action) => {
				state.asyncStatus = 'PENDING_POST_MOOD';
			})
			.addCase(postMood.rejected, (state, action) => {
				state.askMood = true;
				state.asyncStatus = 'REJECTED_POST_MOOD';
			});
		builder
			.addCase(getMoods.fulfilled, (state, action) => {
				state.moods = action.payload;
				state.asyncStatus = 'FULFILLED_GET_MOODS';

				// 今日の気分を登録していない場合は、気分登録のダイアログを表示
				const today = dayjs().format('YYYY-MM-DD');
				if (!state.moods) return;
				const isExistTodayMood = state.moods.some((mood: Mood) => {
					if (dayjs(mood.createdAt).format('YYYY-MM-DD') === today) {
						return true;
					}
					return false;
				});
				if (!isExistTodayMood) {
					state.askMood = true;
				}
			})
			.addCase(getMoods.pending, (state, action) => {
				state.asyncStatus = 'PENDING_GET_MOODS';
			})
			.addCase(getMoods.rejected, (state, action) => {
				state.askMood = false;
				state.asyncStatus = 'REJECTED_GET_MOODS';
			});
	},
});

/**
 * 直近の気分を取得
 * @param userId
 * @returns {Promise<Mood[]>} 直近の気分
 * @example
 * dispatch(getMoods(userId));
 */
export const getMoods = createAsyncThunk('mood/getMoods', async (userId: string) => {
	const res = await axios.get(`${api}/moods/${userId}?page=${moodPage}&limit=${moodLimit}`);
	return res.data;
});

/**
 * 気分を登録する非同期関数
 * @param {User} args.user - ユーザー情報
 * @param {Mood} args.mood - 気分
 * @returns {Promise<Mood[]>} 気分
 * @example
 * dispatch(postMood({ user, mood }));
 */
export const postMood = createAsyncThunk(
	'mood/postMood',
	async (args: { user: User; mood: Mood }) => {
		await axios.post(`${api}/moods/${args.user.id}`, {
			score: args.mood.score,
		});
		const res = await axios.get(`${api}/moods/${args.user.id}?page=${moodPage}&limit=${moodLimit}`);
		return res.data;
	}
);

export default moodSlice.reducer;
