import React from 'react';
import { Avatar } from '@mui/material';

interface MoodIconProps {
  number?: number;
};

export const MoodIcon: React.FC<MoodIconProps> = ({
  number = 0,
}) => {

  const numberToColor = (number: number) => {
    switch (number) {
      case 1:
        return '#82D6F6';
      case 2:
        return '#82D6F6';
      case 3:
        return '#FACF4A';
      case 4:
        return '#F38D8D';
      case 5:
        return '#F38D8D';
      default:
        return '#FFFFFF';
    }
  }

  const numberToEmoji = (number: number) => {
    switch (number) {
      case 1:
        return '😭';
      case 2:
        return '😟';
      case 3:
        return '😐';
      case 4:
        return '😊';
      case 5:
        return '😄';
      default:
        return '😑';
    }
  }

  return (
    <Avatar
      style={{
        backgroundColor: numberToColor(number),
      }}
    >
      { numberToEmoji(number) }
    </Avatar>
  );
};
