import React from 'react';
import { styled } from 'styled-components';
import { Avatar } from '@mui/material';
import { CardHeader } from '@mui/material';

import { User, Community, Member } from '../../../types';
import { BaseAccordion } from '../../molecules/BaseAccordion/BaseAccordion';
import { BaseCard } from '../../molecules/BaseCard/BaseCard';
import { MoodIcon } from '../../atoms/MoodIcon/MoodIcon';
import Diversity3Icon from '@mui/icons-material/Diversity3';


interface RightSidebarProps {
  user?: User;
  communities?: Community[];
  width?: string;
  height?: string;
  onClickMember?: (member: Member) => void;
};

export const RightSidebar: React.FC<RightSidebarProps> = ({
  user,
  communities,
  width = "300px",
  height = "100%",
  onClickMember,
}) => {


  const renderCommunities = (communities?: Community[]) => {
    return communities?.map((community) => {
      return (
        <BaseAccordion
          width={'calc(' + width + ' - 1rem)'}
          summaryChildren={community.name}
          detailChildren={
            <DivContentChildren>
              {renderMembers(community.members)}
            </DivContentChildren>
          }
        />
      );
    });
  }

  const renderMembers = (members?: Member[]) => {
    return members?.map((member) => {
      if (member.user.id === user?.id) return null;
      return (
        <BaseCard
          width = "100%"
          height = "4.5rem"
          headerChildren={
            <CardHeader
              avatar={
                <Avatar>{member.user.name[0]}</Avatar>
              }
              title={member.user.name}
              subheader={member.role.name}
              action={
                <MoodIcon number={member.mood?.score}/>
              }
            />
          }
          onClick={() => onClickMember && onClickMember(member)}
        />
      );
    });
  }

  return (
    <DivRightSidebar width={width} height={height}>

      {
        communities && communities.length > 0
        ? <DivMember>
          {renderCommunities(communities)}
        </DivMember>
        : <DivFlexColumn>
          <DivBold>コミュニティに参加しよう！</DivBold>
          <DivFlexRow>
            <Diversity3Icon />
            <DivText>ボタンからコミュニティに参加してみましょう！</DivText>
          </DivFlexRow>
        </DivFlexColumn>
      }
    </DivRightSidebar>
  );
};

const DivContentChildren = styled.div`
`;

const DivRightSidebar = styled.div< { width: string, height: string } >`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  overflow-y: auto;
`;

const DivMember = styled.div`
`;

const DivFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
`;

const DivFlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const DivBold = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
`;

const DivText = styled.div`
`;

