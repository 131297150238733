import React from 'react';
import { styled } from 'styled-components';
import TextField from '@mui/material/TextField';

interface SimpleTextFieldProps {
  value?: string;
  label?: string;
  width?: string;
  multiline?: boolean;
  helperText?: string;
  onChange?: (value: string) => void;
};

export const SimpleTextField: React.FC<SimpleTextFieldProps> = ({
  value,
  label = "",
  width = "24rem",
  multiline = false,
  helperText,
  onChange,
}) => {
  return (
    <DivSimpleTextField>
      <TextField
        label={label}
        value={value}
        multiline={multiline}
        rows={multiline ? 4 : 1}
        helperText={helperText}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {onChange?.(e.target.value)}}
        style = {{width: width}}
      />
      
    </DivSimpleTextField>
  );
};

const DivSimpleTextField = styled.div`
  margin: 8px;
`;