// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* FullCalendarの配色を変更するためのCSS */

:root {
	/* FullCalendarの配色を変更 */
	/* FullCalendarの仕様上，Styled Componentsでの変更は不可能なため，CSSによりroot要素に変数を設定することで変更 */
	--fc-today-bg-color: rgba(130, 214, 246, 0.15); /* 背景色 */
	--fc-highlight-color: rgba(250, 207, 74, 0.3); /* ドラック時のハイライト色 */
	--fc-event-border-color: #000; /* 各イベントの枠線色 */
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,+BAA+B;;AAE/B;CACC,uBAAuB;CACvB,4EAA4E;CAC5E,8CAA8C,EAAE,QAAQ;CACxD,6CAA6C,EAAE,iBAAiB;CAChE,6BAA6B,EAAE,cAAc;AAC9C","sourcesContent":["/* FullCalendarの配色を変更するためのCSS */\n\n:root {\n\t/* FullCalendarの配色を変更 */\n\t/* FullCalendarの仕様上，Styled Componentsでの変更は不可能なため，CSSによりroot要素に変数を設定することで変更 */\n\t--fc-today-bg-color: rgba(130, 214, 246, 0.15); /* 背景色 */\n\t--fc-highlight-color: rgba(250, 207, 74, 0.3); /* ドラック時のハイライト色 */\n\t--fc-event-border-color: #000; /* 各イベントの枠線色 */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
