import React from 'react';
import { styled } from 'styled-components';
import { Button } from '@mui/material';

import { User } from '../../../types';
import { BaseDialog } from '../../molecules/BaseDialog/BaseDialog';
import { SimpleTextField } from '../../atoms/SimpleTextField/SimpleTextField';

interface LoginDialogProps {
  open?: boolean;
  // user: User;
  initialUser?: User;
  onClose?: () => void;
  onSubmit?: (user: User) => void;
  onRegister?: () => void;
};

const defaultUser: User = {
  id: '',
  name: '',
  description: '',
};

export const LoginDialog: React.FC<LoginDialogProps> = ({
  open,
  initialUser = defaultUser,
  onClose,
  onSubmit,
  onRegister,
}) => {

  const [user, setUser] = React.useState<User>(initialUser);

  return (
    <DivLoginDialog>
      <BaseDialog
        open={open}
        width="xs"
        bottomActionChildren={
          <DivContentChildren>
            <Button
              onClick={onRegister}
            >
              新規登録
            </Button>
            <Button
              onClick={() => {
                onSubmit && onSubmit(user);
              }}
            >
              ログイン
            </Button>
          </DivContentChildren>
        }
        contentChildren={
          <SimpleTextField
            label="ユーザーID"
            value={user.id}
            onChange={(userId: string) => {
              setUser({ ...user, id: userId });
            }}
            helperText='5文字以上の半角英数字'
          />
        }
        onClose={onClose}
        titleChildren="ログイン"
      />
    </DivLoginDialog>
  );
};

const DivLoginDialog = styled.div`
  display: 'flex';
`;

const DivContentChildren = styled.div`
`;
