import React, { useEffect } from 'react';
import { styled } from 'styled-components';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import allLocales from '@fullcalendar/core/locales-all';
import {
	EventInput,
	DateSelectArg,
	EventClickArg,
	EventHoveringArg,
	EventContentArg,
} from '@fullcalendar/core';
// import { EventContentArg } from '@fullcalendar/react';
import dayjs from 'dayjs';

import { User, Community } from '../../../types';

interface MainCalendarProps {
	user?: User;
	communities?: Community[];
	view?: string;
	date?: Date;
	events?: EventInput[];
	width?: string;
	height?: string;
	onDateSelect?: (selectInfo: DateSelectArg) => void;
	onEventClick?: (event: EventClickArg) => void;
	onEventHovering?: (event: EventHoveringArg) => void;
}

export const MainCalendar: React.FC<MainCalendarProps> = ({
	user,
	communities,
	view,
	date,
	events,
	width = '100%',
	height = '500px',
	onDateSelect,
	onEventClick,
	onEventHovering,
}) => {
	const calendarRef = React.useRef<FullCalendar>(null);

	useEffect(() => {
		let calendarApi = calendarRef.current?.getApi();
		if (calendarApi && view) {
			calendarApi.changeView(view);
		}
	}, [view]);

	useEffect(() => {
		let calendarApi = calendarRef.current?.getApi();
		if (calendarApi && date) {
			calendarApi.gotoDate(date);
		}
	}, [date]);

	const formatTime = (time?: string) => {
		return time ? dayjs(time).format('HH:mm') : '';
	};


  const searchUserName = (target_user_id: string) => {
    for (const community of communities || []) {
      for (const member of community.members || []) {
        if (member.user.id === target_user_id) {
          return `${member.user.name}さん(${community.name})`;
        }
      }
    }
    return '他の人';
  };

	const formatOthersEvent = (event: EventInput) => {
		if (event.extendedProps && event.extendedProps.user.id !== user?.id) {
      let userName = searchUserName(event.extendedProps.user.id);
      return `${userName}の予定`;
		}
		return '';
	};

	const renderDayGridEvent = (event: EventInput) => {
		return (
			<DivEventContent>
				<DivFlexRow>
					<DivColorCircle color={event.backgroundColor || 'blue'} />
					<DivFlexColumn>
						<DivEventContentTitle>{event.title}</DivEventContentTitle>
						{!event.allDay ? (
							<DivEventContentText>
								{formatTime(event.start as string)} - {formatTime(event.end as string)}
							</DivEventContentText>
						) : null}
						<DivEventContentText fontWeight="bold">{formatOthersEvent(event)}</DivEventContentText>
					</DivFlexColumn>
				</DivFlexRow>
			</DivEventContent>
		);
	};
	const renderTimeGridEvent = (event: EventInput) => {
		return (
			<DivEventContent>
				<DivFlexColumn>
					<DivEventContentTitle>{event.title}</DivEventContentTitle>
					{!event.allDay ? (
						<DivEventContentText>
							{formatTime(event.start as string)} - {formatTime(event.end as string)}
						</DivEventContentText>
					) : null}
					<DivEventContentText>{event.extendedProps?.state}</DivEventContentText>
					<DivEventContentText fontWeight="bold">{formatOthersEvent(event)}</DivEventContentText>
				</DivFlexColumn>
			</DivEventContent>
		);
	};

	return (
		<DivMainCalendar width={width}>
			<FullCalendar
				key={'main-calendar'}
				plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
				height={height}
				headerToolbar={false}
				initialView={view}
				selectable={true}
				nowIndicator={true}
				locales={allLocales}
				locale="ja"
				events={events}
				select={(selectInfo: DateSelectArg) => {
					onDateSelect && onDateSelect(selectInfo);
				}}
				eventClick={(eventClickInfo: EventClickArg) => {
					onEventClick && onEventClick(eventClickInfo);
				}}
				eventMouseEnter={(eventHoveringInfo: EventHoveringArg) => {
					onEventHovering && onEventHovering(eventHoveringInfo);
				}}
				eventContent={(eventContentArg: EventContentArg) => {
					// イベントの表示内容をカスタマイズ
					let event: EventInput = eventContentArg.event as EventInput;
					let calendarApi = calendarRef.current?.getApi();
					let viewType = calendarApi?.view.type;
					if (viewType === 'dayGridMonth') {
						return renderDayGridEvent(event);
					} else {
						return renderTimeGridEvent(event);
					}
				}}
				ref={calendarRef}
			/>
		</DivMainCalendar>
	);
};

const DivMainCalendar = styled.div<{ width: string }>`
	width: ${(props) => props.width};
`;

const DivEventContent = styled.div``;

const DivEventContentTitle = styled.div`
	font-weight: bold;
	font-size: 0.8rem;
	white-space: normal;
	overflow-wrap: break-word;
`;

const DivEventContentText = styled.div<{ fontWeight?: string }>`
	font-size: 0.6rem;
	font-weight: ${(props) => props.fontWeight || 'normal'};
	white-space: normal;
	overflow-wrap: break-word;
`;

const DivFlexRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const DivFlexColumn = styled.div`
	display: flex;
	flex-direction: column;
`;

const DivColorCircle = styled.div<{ color: string }>`
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: ${(props) => props.color};
	display: inline-block;
	margin: 0 5px;
	flex-shrink: 0;
`;
