/**
 * @file src/index.tsx
 * @description カレンダーアプリのエントリーポイント
 * @module Index
 * @category Entry
 * @require react
 * @require react-dom/client
 * @require react-redux
 * @require ./App
 * @require ./state/store
 * @exports root
 * @exports default
 */

import React from 'react'; // このReactが必須なのかが不明
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import './index.css';
import { App } from './App';
import { store } from './state/store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

/**
 * カレンダーアプリのエントリーポイント
 *
 * @remarks
 * このアプリを./index.htmlで読み込むことでレンダリングされる
 * storeによりアプリ内での状態管理が可能になる
 */
root.render(
	<Provider store={store}>
		<App />
	</Provider>
);
