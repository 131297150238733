import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';
import { EventInput } from '@fullcalendar/core';
import { useSnackbar, OptionsObject } from 'notistack';

import { Community, Notification, User, Mood, Role, Advice } from '../../../types';
import { CommonsCalendarTemplate } from '../../templates/CommonsCalendarTemplate/CommonsCalendarTemplate';
import { RootState, AppDispatch } from '../../../state/store';
import { setUser, resetUser, postUser, getUser, putUser } from '../../../state/ducks/users';
import {
	getCommunities,
	postCommunity,
	deleteCommunity,
	joinCommunity,
	putRole,
	leaveCommunity,
} from '../../../state/ducks/communities';
import { getNotifications } from '../../../state/ducks/notifications';
import { postMood, getMoods } from '../../../state/ducks/moods';
import { getEvents, postEvent, putEvent, deleteEvent } from '../../../state/ducks/events';
import { getAdvice } from '../../../state/ducks/advice';

/**
 * スナックバーのオプションを定義
 */
type Variant = 'default' | 'error' | 'success' | 'warning' | 'info';
const snackbarOptions: (variant: Variant) => OptionsObject = (variant: Variant) => {
	return {
		variant: variant,
		anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
	};
};

/**
 * ステータスに応じたメッセージとスナックバーの種類を定義
 * 処理を共通化する(ex. 'XXのXXにXXしました'のXXを都度入れ替える処理にする)とかえって処理が複雑・柔軟に変更することが難しくなるため，敢えて冗長な形で定義
 * @param status
 * @returns
 */
const statusMap: { [key: string]: { message: string; type: Variant } } = {
	// ユーザー関連
	FULFILLED_GET_USER: { message: '', type: 'default' }, // 未使用なのでメッセージは空
	REJECTED_GET_USER: { message: 'ログインに失敗しました', type: 'error' },
	FULFILLED_POST_USER: { message: 'ユーザーを登録しました', type: 'success' },
	REJECTED_POST_USER: { message: 'ユーザーの登録に失敗しました', type: 'error' },
	FULFILLED_PUT_USER: { message: 'ユーザー情報を更新しました', type: 'success' },
	REJECTED_PUT_USER: { message: 'ユーザー情報の更新に失敗しました', type: 'error' },

	// イベント関連
	FULFILLED_GET_EVENTS: { message: '', type: 'default' }, // 未使用なのでメッセージは空
	REJECTED_GET_EVENTS: { message: 'イベント情報の取得に失敗しました', type: 'error' },
	FULFILLED_POST_EVENT: { message: 'イベントを作成しました', type: 'success' },
	REJECTED_POST_EVENT: { message: 'イベントの作成に失敗しました', type: 'error' },
	FULFILLED_PUT_EVENT: { message: 'イベント情報を更新しました', type: 'success' },
	REJECTED_PUT_EVENT: { message: 'イベント情報の更新に失敗しました', type: 'error' },
	FULFILLED_DELETE_EVENT: { message: 'イベントを削除しました', type: 'default' },
	REJECTED_DELETE_EVENT: { message: 'イベントの削除に失敗しました', type: 'error' },

	// コミュニティ関連
	FULFILLED_GET_COMMUNITIES: { message: '', type: 'default' }, // 未使用なのでメッセージは空
	REJECTED_GET_COMMUNITIES: { message: 'コミュニティ情報の取得に失敗しました', type: 'error' },
	FULFILLED_POST_COMMUNITY: { message: 'コミュニティを作成しました', type: 'success' },
	REJECTED_POST_COMMUNITY: { message: 'コミュニティの作成に失敗しました', type: 'error' },
	FULFILLED_PUT_COMMUNITY: { message: 'コミュニティ情報を更新しました', type: 'success' },
	REJECTED_PUT_COMMUNITY: { message: 'コミュニティ情報の更新に失敗しました', type: 'error' },
	FULFILLED_DELETE_COMMUNITY: { message: 'コミュニティを削除しました', type: 'default' },
	REJECTED_DELETE_COMMUNITY: { message: 'コミュニティの削除に失敗しました', type: 'error' },
	FULFILLED_JOIN_COMMUNITY: { message: 'コミュニティに参加しました', type: 'success' },
	REJECTED_JOIN_COMMUNITY: { message: 'コミュニティへの参加に失敗しました', type: 'error' },
	FULFILLED_PUT_ROLE: { message: '役割を変更しました', type: 'success' },
	REJECTED_PUT_ROLE: { message: '役割の変更に失敗しました', type: 'error' },
	FULFILLED_LEAVE_COMMUNITY: { message: 'コミュニティから脱退しました', type: 'default' },
	REJECTED_LEAVE_COMMUNITY: { message: 'コミュニティからの脱退に失敗しました', type: 'error' },

	// 気分関連
	FULFILLED_GET_MOODS: { message: '', type: 'default' }, // 未使用なのでメッセージは空
	REJECTED_GET_MOODS: { message: '気分情報の取得に失敗しました', type: 'error' },
	FULFILLED_POST_MOOD: { message: '気分を記録しました', type: 'success' },
	REJECTED_POST_MOOD: { message: '気分の記録に失敗しました', type: 'error' },

	// お知らせ関連
	FULFILLED_GET_NOTIFICATIONS: { message: '', type: 'default' }, // 未使用なのでメッセージは空
	REJECTED_GET_NOTIFICATIONS: { message: 'お知らせの取得に失敗しました', type: 'error' },

	// アドバイス関連
	FULFILLED_GET_ADVICE: { message: '', type: 'default' }, // 未使用なのでメッセージは空
	REJECTED_GET_ADVICE: { message: 'アドバイスの取得に失敗しました', type: 'error' },
};

interface CommonsCalendarProps {}

export const CommonsCalendar: React.FC<CommonsCalendarProps> = ({}) => {
	/**
	 * 共通の処理
	 */
	const dispatch: AppDispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	// スナックバーの表示
	const handleSnackbar = (status?: string) => {
		if (!status) return;
		const { message, type } = statusMap[status] || {};
		if (message) {
			enqueueSnackbar(message, snackbarOptions(type));
		}
	};

	/**
	 * ユーザー関連の処理
	 */
	const user: User | undefined = useSelector((state: RootState) => state.users.user);

	const userAsyncStatus: string | undefined = useSelector(
		(state: RootState) => state.users.asyncStatus
	);

	useEffect(() => {
		if (!user || !user.id) return;
		dispatch(getUser(user.id));
	}, [user?.id]);

	useEffect(() => {
		handleSnackbar(userAsyncStatus);
		if (!user || !user.id) return;
		dispatch(getMoods(user.id));
		dispatch(getEvents(user.id));
		dispatch(getCommunities(user.id));
		dispatch(getNotifications());
		dispatch(getAdvice(user.id));
	}, [userAsyncStatus]);

	/**
	 * コミュニティ関連の処理
	 */
	const communities: Community[] | undefined = useSelector(
		(state: RootState) => state.communities.communities
	);

	const communitiesAsyncStatus: string | undefined = useSelector(
		(state: RootState) => state.communities.asyncStatus
	);

	useEffect(() => {
		handleSnackbar(communitiesAsyncStatus);
	}, [communitiesAsyncStatus]);

	/**
	 * イベント関連の処理
	 */
	const events: EventInput[] | undefined = useSelector((state: RootState) => state.events.events);

	const eventsAsyncStatus: string | undefined = useSelector(
		(state: RootState) => state.events.asyncStatus
	);

	useEffect(() => {
		handleSnackbar(eventsAsyncStatus);
	}, [eventsAsyncStatus]);

	/**
	 * 気分関連の処理
	 */
	const moods: Mood[] | undefined = useSelector((state: RootState) => state.moods.moods);
	const askMood: boolean | undefined = useSelector((state: RootState) => state.moods.askMood);

	const moodsAsyncStatus: string | undefined = useSelector(
		(state: RootState) => state.moods.asyncStatus
	);

	/**
	 * お知らせ関連の処理
	 */
	const notifications: Notification[] | undefined = useSelector(
		(state: RootState) => state.notifications.notifications
	);

	const notificationsAsyncStatus: string | undefined = useSelector(
		(state: RootState) => state.notifications.asyncStatus
	);

	/**
	 * アドバイス関連の処理
	 */
	const advice: Advice | undefined = useSelector((state: RootState) => state.advice.advice);
	const adviceAsyncStatus: string | undefined = useSelector(
		(state: RootState) => state.advice.asyncStatus
	);

	useEffect(() => {
		handleSnackbar(moodsAsyncStatus);
	}, [moodsAsyncStatus]);

	useEffect(() => {
		handleSnackbar(notificationsAsyncStatus);
	}, [notificationsAsyncStatus]);

	useEffect(() => {
		handleSnackbar(adviceAsyncStatus);
	}, [adviceAsyncStatus]);

	return (
		<DivCommonsCalendar>
			<CommonsCalendarTemplate
				/**
				 * ユーザー関連
				 */
				user={user}
				onRegister={(submittedUser: User) => {
					dispatch(postUser(submittedUser));
				}}
				onLogin={(submittedUser: User) => {
					dispatch(setUser(submittedUser));
				}}
				onLogout={() => {
					dispatch(resetUser());
				}}
				onUpdateUser={(submittedUser: User) => {
					dispatch(putUser(submittedUser));
				}}
				/**
				 * コミュニティ関連
				 */
				communities={communities}
				onCreateCommunity={(submittedCommunity: Community, submittedRole: Role) => {
					if (!user) return; //! 繰り返し出てくる処理で冗長なので高階関数で共通化したい
          console.log(submittedCommunity)
					dispatch(
						postCommunity({ user: user, community: submittedCommunity, role: submittedRole })
					);
				}}
				onJoinCommunity={(submittedCommunity: Community, submittedRole: Role) => {
					if (!user) return;
					dispatch(
						joinCommunity({ user: user, community: submittedCommunity, role: submittedRole })
					);
				}}
				onLeaveCommunity={(submittedCommunity: Community) => {
					if (!user) return;
					dispatch(leaveCommunity({ user: user, community: submittedCommunity }));
				}}
				onDeleteCommunity={(submittedCommunity: Community) => {
					if (!user) return;
					dispatch(deleteCommunity({ user: user, community: submittedCommunity }));
				}}
				onChangeRole={(submittedCommunity: Community, submittedRole: Role) => {
					if (!user) return;
					dispatch(putRole({ user: user, community: submittedCommunity, role: submittedRole }));
				}}
				/**
				 * イベント関連
				 */
				events={events}
				onEventCreate={(submittedEvent: EventInput) => {
					if (!user) return;
					dispatch(postEvent({ user: user, event: submittedEvent }));
				}}
				onEventUpdate={(submittedEvent: EventInput) => {
					if (!user) return;
					dispatch(putEvent({ user: user, event: submittedEvent }));
				}}
				onEventDelete={(submittedEvent: EventInput) => {
					if (!user) return;
					dispatch(deleteEvent({ user: user, event: submittedEvent }));
				}}
				/**
				 * 気分関連
				 */
				moods={moods}
				askMood={askMood}
				onMoodCreate={(submittedMood: Mood) => {
					if (!user) return;
					dispatch(postMood({ user: user, mood: submittedMood }));
				}}
				/**
				 * お知らせ関連
				 */
				notifications={notifications}
				/**
				 * アドバイス関連
				 */
				advice={advice}
			/>
		</DivCommonsCalendar>
	);
};

const DivCommonsCalendar = styled.div`
	display: 'flex';
`;
