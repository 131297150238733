/**
 * @file src/state/ducks/events.ts
 * @description イベントに関するRedux Duck
 * @module Ducks/Events
 * @category State
 * @require module:axios
 * @require module:@reduxjs/toolkit
 * @require module:@fullcalendar/core
 * @require module:../../types
 * @require module:../../config
 * @exports eventState
 * @exports eventSlice
 * @exports getEvents
 * @exports postEvent
 * @exports putEvent
 * @exports deleteEvent
 * @exports default
 */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { EventInput } from '@fullcalendar/core';

import { User } from '../../types';

import { api } from '../../config';

export interface eventState {
	asyncStatus?: string;
	events?: EventInput[];
}

const initialState: eventState = {
	asyncStatus: undefined,
	events: [],
};

export const eventSlice = createSlice({
	name: 'event',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getEvents.fulfilled, (state, action) => {
				state.events = action.payload;
				state.asyncStatus = 'FULFILLED_GET_EVENTS';
			})
			.addCase(getEvents.pending, (state, action) => {
				state.asyncStatus = 'PENDING_GET_EVENTS';
			})
			.addCase(getEvents.rejected, (state, action) => {
				state.asyncStatus = 'REJECTED_GET_EVENTS';
			});

		builder
			.addCase(postEvent.fulfilled, (state, action) => {
				var events = action.payload;

        // POSTのみイベントが重複表示されるため，idで排除する
        events = events.filter((event: EventInput, index: number, self: EventInput[]) =>
          index === self.findIndex((e) => e.id === event.id)
        );
        state.events = events;

				state.asyncStatus = 'FULFILLED_POST_EVENT';
			})
			.addCase(postEvent.pending, (state, action) => {
				state.asyncStatus = 'PENDING_POST_EVENT';
			})
			.addCase(postEvent.rejected, (state, action) => {
				state.asyncStatus = 'REJECTED_POST_EVENT';
			});

		builder
			.addCase(putEvent.fulfilled, (state, action) => {
				state.events = action.payload;
				state.asyncStatus = 'FULFILLED_PUT_EVENT';
			})
			.addCase(putEvent.pending, (state, action) => {
				state.asyncStatus = 'PENDING_PUT_EVENT';
			})
			.addCase(putEvent.rejected, (state, action) => {
				state.asyncStatus = 'REJECTED_PUT_EVENT';
			});

		builder
			.addCase(deleteEvent.fulfilled, (state, action) => {
				state.events = action.payload;
				state.asyncStatus = 'FULFILLED_DELETE_EVENT';
			})
			.addCase(deleteEvent.pending, (state, action) => {
				state.asyncStatus = 'PENDING_DELETE_EVENT';
			})
			.addCase(deleteEvent.rejected, (state, action) => {
				state.asyncStatus = 'REJECTED_DELETE_EVENT';
			});
	},
});

/**
 * イベントを取得する非同期関数
 *
 * @param {string} userId - ユーザーID
 * @returns {Promise<EventInput[]>} イベント
 * @example
 * dispatch(getEvents(userId));
 */
export const getEvents = createAsyncThunk('event/getEvents', async (userId: string) => {
	const res = await axios.get(`${api}/events/${userId}`);
	return res.data;
});

/**
 * イベントを新規作成する非同期関数
 * @param {User} args.user - ユーザー情報
 * @param {EventInput} args.event - イベント情報
 * @returns {Promise<EventInput[]>} イベント
 * @example
 * dispatch(postEvent({ user, event }));
 * @example
 */
export const postEvent = createAsyncThunk(
	'event/postEvent',
	async (args: { user: User; event: EventInput }) => {
		delete args.event.id;
		await axios.post(`${api}/events/${args.user.id}`, args.event);
		const res = await axios.get(`${api}/events/${args.user.id}`);
		return res.data;
	}
);

/**
 * イベントを更新する非同期関数
 * @param {User} args.user - ユーザー情報
 * @param {EventInput} args.event - イベント情報
 * @returns {Promise<EventInput[]>} イベント
 * @example
 * dispatch(putEvent({ user, event }));
 */
export const putEvent = createAsyncThunk(
	'event/putEvent',
	async (args: { user: User; event: EventInput }) => {
		const eventId = args.event.id;
		delete args.event.id;
		await axios.put(`${api}/events/${eventId}`, args.event);
		const res = await axios.get(`${api}/events/${args.user.id}`);
		return res.data;
	}
);

/**
 * イベントを削除する非同期関数
 * @param {User} args.user - ユーザー情報
 * @param {EventInput} args.event - イベント情報
 * @returns {Promise<EventInput[]>} イベント
 * @example
 * dispatch(deleteEvent({ user, event }));
 */
export const deleteEvent = createAsyncThunk(
	'event/deleteEvent',
	async (args: { user: User; event: EventInput }) => {
		await axios.delete(`${api}/events/${args.event.id}`);
		const res = await axios.get(`${api}/events/${args.user.id}`);
		return res.data;
	}
);

export default eventSlice.reducer;
