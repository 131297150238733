import React from 'react';
import { styled } from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateCalendar } from '@mui/x-date-pickers';

import { Mood } from '../../../types';
import { LineGraph } from '../../molecules/LineGraph/LineGraph';

interface LeftSidebarProps {
	width?: string;
	height?: string;
	date?: Dayjs;
	moods?: Mood[];
	onChangeDate?: (date: Dayjs | null) => void;
}

export const LeftSidebar: React.FC<LeftSidebarProps> = ({
	width = '300px',
	height = '100%',
	date = dayjs(),
	moods = [],
	onChangeDate,
}) => {
  const convertMoods = (moods: Mood[] | undefined) => {
    if (!moods) {
      return undefined;
    }

    // moodsを日付順にソート
    const sortedMoods = [...moods].sort((a, b) => {
      const dateA = a.createdAt ? dayjs(a.createdAt).toDate().getTime() : 0;
      const dateB = b.createdAt ? dayjs(b.createdAt).toDate().getTime() : 0;
      return dateA - dateB;
    });

    return {
      labels: sortedMoods.map((mood) => {
        if (!mood.createdAt) {
          return 'NA';
        }
        return dayjs(mood.createdAt).format('MM/DD');
      }),
      datasets: [
        {
          label: '気分',
          data: sortedMoods.map((mood) => mood.score),
          fill: true,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 1,
        },
      ],
    };
  };

	return (
		<DivLeftSidebar
			width={width}
			height={height}
		>
			<DivDateCalendar>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DateCalendar
						value={date}
						showDaysOutsideCurrentMonth
						fixedWeekNumber={6}
						onChange={(value: Dayjs | null) => onChangeDate && onChangeDate(value)}
					/>
				</LocalizationProvider>
			</DivDateCalendar>
			<DivMood>
				{moods.length === 0 ? (
					<DivBold>気分のデータがありません</DivBold>
				) : (
					<LineGraph
						data={convertMoods(moods)}
						width={width}
					/>
				)}
			</DivMood>
		</DivLeftSidebar>
	);
};

const DivLeftSidebar = styled.div<{ width: string; height: string }>`
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	display: flex;
	flex-direction: column;
	overflow-y: auto;
`;

const DivDateCalendar = styled.div``;

const DivMood = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const DivBold = styled.div`
	font-weight: bold;
	font-size: 1.2rem;
	margin: 10rem 0;
`;
