/**
 * @file src/state/ducks/notifications.ts
 * @description 通知に関するRedux Duck
 * @module Ducks/Notifications
 * @category State
 * @require module:axios
 * @require module:@reduxjs/toolkit
 * @require module:../../types
 * @require module:../../config
 * @exports notificationState
 * @exports notificationSlice
 * @exports getNotifications
 * @exports default
 */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { Notification } from '../../types';
import { notificationPage, notificationLimit } from '../../config';

import { api } from '../../config';

export interface notificationState {
	asyncStatus?: string;
	notifications?: Notification[];
}

const initialState: notificationState = {
	asyncStatus: undefined,
	notifications: [],
};

export const notificationSlice = createSlice({
	name: 'notification',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getNotifications.fulfilled, (state, action) => {
				state.notifications = action.payload;
				state.asyncStatus = 'FULFILLED_GET_NOTIFICATIONS';
			})
			.addCase(getNotifications.pending, (state, action) => {
				state.asyncStatus = 'PENDING_GET_NOTIFICATIONS';
			})
			.addCase(getNotifications.rejected, (state, action) => {
				state.asyncStatus = 'REJECTED_GET_NOTIFICATIONS';
			});
	},
});

/**
 * 通知を取得する非同期関数
 *
 * @returns {Promise<Notification[]>} 通知
 * @example
 * dispatch(getNotifications());
 */
export const getNotifications = createAsyncThunk('notification/getNotifications', async () => {
	const res = await axios.get(
		`${api}/notifications?page=${notificationPage}&limit=${notificationLimit}`
	);
	return res.data;
});

export default notificationSlice.reducer;
