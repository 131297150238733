/**
 * @file App.tsx
 * @description カレンダーアプリ本体
 * @module App
 * @category Component
 * @require react
 * @require ./components/pages/CommonsCalendar/CommonsCalendar
 * @require notistack
 * @require ./config
 * @exports App
 * @exports default
 * @example <App />
 * @see https://material-ui.com/components/snackbars/
 */

import React from 'react';

import { CommonsCalendar } from './components/pages/CommonsCalendar/CommonsCalendar';
import { SnackbarProvider } from 'notistack';

import { maxSnack } from './config';

/**
  カレンダーアプリ本体

  @remarks
  このアプリを./index.tsxで読み込むことでレンダリングされる

  @returns レンダリングされるコンポーネント
 */
export const App: React.FC<{}> = () => {
	return (
		<SnackbarProvider maxSnack={maxSnack}>
			<CommonsCalendar />
		</SnackbarProvider>
	);
};
