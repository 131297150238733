import React from 'react';
import { styled } from 'styled-components';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

interface LineGraphProps {
  data?: ChartData<"line">;
  height?: number | string;
  width?: number | string;
};

export const LineGraph: React.FC<LineGraphProps> = ({
  data,
  height = 300,
  width = 400,
}) => {
  return (
    <DivLineGraph>
      <Line
        data={data || {datasets: []}}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          animation: false,
          scales: {
            x: {},
            y: {},
          },
          plugins: {
            filler: {
                propagate: true,
            },
          }
        }}
        height={height}
        width={width}
      />
    </DivLineGraph>
  );
};

const DivLineGraph = styled.div`
  display: 'flex';
`;
