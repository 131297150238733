import React from 'react';
import { styled } from 'styled-components';
import { Button } from '@mui/material';
import { DialogContentText } from '@mui/material';

import { BaseDialog } from '../../molecules/BaseDialog/BaseDialog';

interface LogoutDialogProps {
  open?: boolean;
  onClose?: () => void;
  onEnter?: () => void;
};

export const LogoutDialog: React.FC<LogoutDialogProps> = ({
  open,
  onClose,
  onEnter,
}) => {
  return (
    <DivLogoutDialog>
      <BaseDialog
        open={open}
        width="xs"
        bottomActionChildren={
          <DivContentChildren>
            <Button
              onClick={onClose}
            >
              キャンセル
            </Button>
            <Button
              onClick={onEnter}
            >
              ログアウト
            </Button>
          </DivContentChildren>
        }
        contentChildren={
          <DialogContentText>
            ログアウトしますか？
          </DialogContentText>
        }
        onClose={onClose}
        titleChildren="ログアウト"
      />
    </DivLogoutDialog>
  );
};

const DivLogoutDialog = styled.div`
  display: 'flex';
`;

const DivContentChildren = styled.div`
`;