import React from 'react';
import { styled } from 'styled-components';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface BaseAccordionProps {
  defaultExpanded?: boolean;
  expandIcon?: React.ReactNode;
  width?: string;
  summaryChildren?: React.ReactNode;
  detailChildren?: React.ReactNode;
};

export const BaseAccordion: React.FC<BaseAccordionProps> = ({
  defaultExpanded = true,
  expandIcon = <ExpandMoreIcon />,
  width = "24rem",
  summaryChildren,
  detailChildren,
}) => {
  return (
    <DivAccordion
      width={width}
    >
      <Accordion defaultExpanded={defaultExpanded}>
        <AccordionSummary expandIcon={expandIcon}>
          {summaryChildren}
        </AccordionSummary>
        <AccordionDetails>
          {detailChildren}
        </AccordionDetails>
      </Accordion>
    </DivAccordion>
  );
};

const DivAccordion = styled.div<{ width: string }>`
  width: ${({ width }) => width};
`;
