import React from 'react';
import dayjs from 'dayjs';
import { styled } from 'styled-components';
import { Button } from '@mui/material';
import { Slider } from '@mui/material';
import { DialogContentText } from '@mui/material';

import { Mood, User } from '../../../types';
import { BaseDialog } from '../../molecules/BaseDialog/BaseDialog';

interface MoodAskDialogProps {
	user?: User;
	open?: boolean;
	initialMood?: Mood;
	onClose?: () => void;
	onSubmit?: (mood: Mood) => void;
}

const defaultMood: Mood = {
	id: '',
	userId: '',
	createdAt: dayjs(),
	score: 3,
};

export const MoodAskDialog: React.FC<MoodAskDialogProps> = ({
	user,
	open,
	initialMood = defaultMood,
	onClose,
	onSubmit,
}) => {
	const [mood, setMood] = React.useState<Mood>(initialMood);

	return (
		<DivMoodAskDialog>
			<BaseDialog
				open={open}
				width="xs"
				contentChildren={
					<DivContentChildren>
						<DialogContentText>今日の気分は？</DialogContentText>
						<DivBlankBox />
						<Slider
							value={mood.score}
							step={1}
							marks={[
								{ value: 1, label: '😭' },
								{ value: 5, label: '😄' },
							]}
							min={1}
							max={5}
							onChange={(e: Event, value: number | number[]) => {
								if (typeof value !== 'number') return;
								setMood({ id: '', userId: user?.id || '', createdAt: dayjs(), score: value });
							}}
						/>
					</DivContentChildren>
				}
				bottomActionChildren={<Button onClick={() => onSubmit && onSubmit(mood)}>送信</Button>}
				onClose={onClose}
				titleChildren="Commons Calendarへようこそ！"
			/>
		</DivMoodAskDialog>
	);
};

const DivMoodAskDialog = styled.div`
	display: 'flex';
`;

const DivBlankBox = styled.div`
	height: 1rem;
`;

const DivContentChildren = styled.div``;
