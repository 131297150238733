import React, { useEffect } from 'react';
import { styled } from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';
import { EventInput, DateSelectArg, EventClickArg } from '@fullcalendar/core';

import { Community, Notification, User, Mood, Member, Role, Advice } from '../../../types';
import { Header } from '../../organisms/Header/Header';
import { MainCalendar } from '../../organisms/MainCalendar/MainCalendar';
import { LeftSidebar } from '../../organisms/LeftSidebar/LeftSidebar';
import { RightSidebar } from '../../organisms/RightSidebar/RightSidebar';
import { CommunityCreateDialog } from '../../organisms/CommunityCreateDialog/CommunityCreateDialog';
import { CommunityJoinDialog } from '../../organisms/CommunityJoinDialog/CommunityJoinDialog';
import { CommunityLeaveDialog } from '../../organisms/CommunityLeaveDialog/CommunityLeaveDialog';
import { CommunityShowDialog } from '../../organisms/CommunityShowDialog/CommunityShowDialog';
import { MemberShowDialog } from '../../organisms/MemberShowDialog/MemberShowDialog';
import { EventDeleteDialog } from '../../organisms/EventDeleteDialog/EventDeleteDialog';
import { EventDialog } from '../../organisms/EventDialog/EventDialog';
import { LoginDialog } from '../../organisms/LoginDialog/LoginDialog';
import { LogoutDialog } from '../../organisms/LogoutDialog/LogoutDialog';
import { MoodAskDialog } from '../../organisms/MoodAskDialog/MoodAskDialog';
import { NotificationListShowDialog } from '../../../components/organisms/NotificationListShowDialog/NotificationListShowDialog';
import { NotificationShowDialog } from '../../organisms/NotificationShowDialog/NotificationShowDialog';
import { UserUpdateDialog } from '../../organisms/UserUpdateDialog/UserUpdateDialog';
import { RegisterDialog } from '../../organisms/RegisterDialog/RegisterDialog';
import { DivSwitchFlexbox } from '../../atoms/DivSwitchFlexbox/DivSwitchFlexbox';
import { AdviceCard } from '../../organisms/AdviceCard/AdviceCard';

interface DialogBooleans {
	communityCreateDialog: boolean;
	communityDeleteDialog: boolean;
	communityJoinDialog: boolean;
	communityLeaveDialog: boolean;
	communityShowDialog: boolean;
	memberShowDialog: boolean;
	eventDeleteDialog: boolean;
	eventDialog: boolean;
	loginDialog: boolean;
	logoutDialog: boolean;
	notificationListShowDialog: boolean;
	notificationShowDialog: boolean;
	userUpdateDialog: boolean;
	registerDialog: boolean;
}

const initialOpenDialogs: DialogBooleans = {
	communityCreateDialog: false,
	communityDeleteDialog: false,
	communityJoinDialog: false,
	communityLeaveDialog: false,
	communityShowDialog: false,
	memberShowDialog: false,
	eventDeleteDialog: false,
	eventDialog: false,
	loginDialog: false,
	logoutDialog: false,
	notificationListShowDialog: false,
	notificationShowDialog: false,
	userUpdateDialog: false,
	registerDialog: false,
};

interface CommonsCalendarTemplateProps {
	user?: User;
	communities?: Community[];
	notifications?: Notification[];
	moods?: Mood[];
	events?: EventInput[];
	members?: Member[];
	askMood?: boolean;
	advice?: Advice;
	onCreateCommunity?: (community: Community, role: Role) => void;
	onJoinCommunity?: (community: Community, role: Role) => void;
	onLeaveCommunity?: (community: Community) => void;
	onDeleteCommunity?: (community: Community) => void;
	onChangeRole?: (community: Community, role: Role) => void;
	onEventCreate?: (event: EventInput) => void;
	onEventUpdate?: (event: EventInput) => void;
	onEventDelete?: (event: EventInput) => void;
	onMoodCreate?: (mood: Mood) => void;
	onUpdateUser?: (user: User) => void;
	onRegister?: (user: User) => void;
	onLogin?: (user: User) => void;
	onLogout?: () => void;
}

export const CommonsCalendarTemplate: React.FC<CommonsCalendarTemplateProps> = ({
	user,
	communities,
	notifications,
	moods,
	events,
	askMood,
	advice,
	onCreateCommunity,
	onJoinCommunity,
	onLeaveCommunity,
	onChangeRole,
	onEventCreate,
	onEventUpdate,
	onEventDelete,
	onMoodCreate,
	onUpdateUser,
	onRegister,
	onLogin,
	onLogout,
}) => {
	/**
	 * ローカルステート
	 * TemplateではAPIとのデータ送受信を行わないステートのみを扱う
	 */
	/**
	 * カレンダーの表示設定
	 * dayGridMonth: 月表示
	 * timeGridWeek: 週表示
	 * timeGridDay: 日表示
	 * @param view 表示設定
	 * @param setView 表示設定の変更関数
	 */
	const [view, setView] = React.useState<string>('timeGridWeek');
	/**
	 * サイドバーの表示設定
	 * trueで表示、falseで非表示
	 * @param openLeftSidebar サイドバーの表示設定
	 * @param setOpenLeftSidebar サイドバーの表示設定の変更関数
	 */
	const [openLeftSidebar, setOpenLeftSidebar] = React.useState<boolean>(true);
	/**
	 * 選択中の日付
	 * @param date 選択中の日付
	 * @param setDate 選択中の日付の変更関数
	 */
	const [date, setDate] = React.useState<Dayjs>(dayjs());
	/**
	 * 選択中のコミュニティ
	 * @param community 選択中のコミュニティ
	 * @param setCommunity 選択中のコミュニティの変更関数
	 */
	const [community, setCommunity] = React.useState<Community | undefined>(undefined);
	/**
	 * 選択中のメンバー
	 * @param member 選択中のメンバー
	 * @param setMember 選択中のメンバーの変更関数
	 */
	const [member, setMember] = React.useState<Member | undefined>(undefined);
	/**
	 * 選択中の通知
	 * @param notification 選択中の通知
	 * @param setNotification 選択中の通知の変更関数
	 */
	const [notification, setNotification] = React.useState<Notification | undefined>(undefined);
	/**
	 * 選択中のイベント
	 * @param event 選択中のイベント
	 * @param setEvent 選択中のイベントの変更関数
	 */
	const [event, setEvent] = React.useState<EventInput | undefined>(undefined);
	/**
	 * ダイアログの表示設定
	 * いずれもtrueで表示、falseで非表示
	 * @param openDialogs ダイアログの表示設定
	 * @param setOpenDialogs ダイアログの表示設定の変更関数
	 */
	const [openDialogs, setOpenDialogs] = React.useState<DialogBooleans>(initialOpenDialogs);
	/**
	 * 開くべきダイアログがイベント更新ダイアログかどうか
	 * trueで更新ダイアログ、falseで新規作成ダイアログ
	 * @param isEventUpdate イベント更新ダイアログかどうか
	 * @param setIsEventUpdate イベント更新ダイアログかどうかの変更関数
	 */
	const [isEventUpdate, setIsEventUpdate] = React.useState<boolean>(false);

	/**
	 * レイアウトの設定
	 */
	const headerHeight: string = '4rem';
	const AdviceCardHeight: string = '20vh';
	const LeftSidebarWidth: string = '330px';
	const LeftSidebarHeight: string = `calc(95vh - ${headerHeight})`;
	const RightSidebarWidth: string = '330px';
	const RightSidebarHeight: string = `calc(95vh - ${headerHeight} - ${AdviceCardHeight})`;
	const MainCalendarWidth: string = openLeftSidebar
		? `calc(100% - ${LeftSidebarWidth} - ${RightSidebarWidth})`
		: `calc(100% - ${RightSidebarWidth})`; //! 三項演算子だが分かりにくいかもしれない

	useEffect(() => {
		if (user) {
			setOpenDialogs({ ...openDialogs, loginDialog: false, registerDialog: false });
		} else {
			setOpenDialogs({ ...openDialogs, loginDialog: true });
		}
	}, [user, openDialogs.loginDialog, openDialogs.registerDialog]);

  useEffect(() => {
    if (communities && communities.length > 0) {
      setCommunity(communities[0]);
    }
  }, [communities]);

	const renderHeader = () => {
		return (
			<DivHeader>
				<Header
					height={headerHeight}
					user={user}
					communities={communities}
					notifications={notifications}
					view={view}
					onClickMenu={() => setOpenLeftSidebar(!openLeftSidebar)}
					onChangeView={(view: string) => setView(view)}
					onClickNotification={() =>
						setOpenDialogs({ ...openDialogs, notificationListShowDialog: true })
					}
					onClickCommunity={(clickedCommunity: Community) => {
						setCommunity(clickedCommunity);
						setOpenDialogs({ ...openDialogs, communityShowDialog: true });
					}}
					onClickCreateCommunity={() =>
						setOpenDialogs({ ...openDialogs, communityCreateDialog: true })
					}
					onClickJoinCommunity={() => setOpenDialogs({ ...openDialogs, communityJoinDialog: true })}
					onClickProfile={() => setOpenDialogs({ ...openDialogs, userUpdateDialog: true })}
					onClickLogout={() => setOpenDialogs({ ...openDialogs, logoutDialog: true })}
				/>
			</DivHeader>
		);
	};

	const renderLeftSidebar = () => {
		return (
			<DivLeftSidebar>
				<LeftSidebar
					width={LeftSidebarWidth}
					height={LeftSidebarHeight}
					date={date}
					moods={moods}
					onChangeDate={(date: Dayjs | null) => {
						if (date) setDate(date);
					}}
				/>
			</DivLeftSidebar>
		);
	};

	const renderMainCalendar = () => {
		return (
			<DivMainCalendar width={MainCalendarWidth}>
				<MainCalendar
					user={user}
					communities={communities}
					height={LeftSidebarHeight}
					view={view}
					date={date.toDate()}
					events={events}
					onDateSelect={(selectInfo: DateSelectArg) => {
						setEvent({
							id: '',
							title: '',
							start: selectInfo.start,
							end: selectInfo.end,
							allDay: false,
							daysOfWeek: [],
							backgroundColor: '#F38D8D',
							extendedProps: {
								user: user,
								community: community,
								state: 'ごめんなさい、今は難しい...',
								routine: false,
								description: '',
								public: false,
								member: [], // TODO
							},
						});
						setIsEventUpdate(false);
						setOpenDialogs({ ...openDialogs, eventDialog: true });
					}}
					onEventClick={(eventClickInfo: EventClickArg) => {
						setEvent(eventClickInfo.event.toPlainObject());
						setIsEventUpdate(true);
						setOpenDialogs({ ...openDialogs, eventDialog: true });
					}}
				/>
			</DivMainCalendar>
		);
	};

	const renderRightSidebar = () => {
		return (
			<DivRightSidebar>
				<AdviceCard
					advice={advice}
					width={RightSidebarWidth}
					height={AdviceCardHeight}
				/>
				<RightSidebar
					width={RightSidebarWidth}
					height={RightSidebarHeight}
					user={user}
					communities={communities}
					onClickMember={(member: Member) => {
						setMember(member);
						setOpenDialogs({ ...openDialogs, memberShowDialog: true });
					}}
				/>
			</DivRightSidebar>
		);
	};

	const renderDialogs = () => {
		return (
			<DivDialogs>
				<CommunityCreateDialog
					open={openDialogs.communityCreateDialog}
					onClose={() => setOpenDialogs({ ...openDialogs, communityCreateDialog: false })}
					onSubmit={(submittedCommunity: Community, submittedRole: Role) => {
						setOpenDialogs({ ...openDialogs, communityCreateDialog: false });
						onCreateCommunity && onCreateCommunity(submittedCommunity, submittedRole);
					}}
				/>
				<CommunityJoinDialog
					open={openDialogs.communityJoinDialog}
					onClose={() => setOpenDialogs({ ...openDialogs, communityJoinDialog: false })}
					onSubmit={(submittedCommunity: Community, submittedRole: Role) => {
						setOpenDialogs({ ...openDialogs, communityJoinDialog: false });
						onJoinCommunity && onJoinCommunity(submittedCommunity, submittedRole);
					}}
				/>
				<CommunityLeaveDialog
					open={openDialogs.communityLeaveDialog}
					onClose={() => setOpenDialogs({ ...openDialogs, communityLeaveDialog: false })}
					onEnter={() => {
						if (!community || !onLeaveCommunity) return;
						setOpenDialogs({
							...openDialogs,
							communityLeaveDialog: false,
							communityShowDialog: false,
						});
						onLeaveCommunity(community);
					}}
				/>
				<CommunityShowDialog
					open={openDialogs.communityShowDialog}
					onClose={() => setOpenDialogs({ ...openDialogs, communityShowDialog: false })}
					onLeave={() => setOpenDialogs({ ...openDialogs, communityLeaveDialog: true })}
					onSubmitRole={(role: Role) => {
						if (!community || !onChangeRole) return;
						setOpenDialogs({ ...openDialogs, communityShowDialog: false });
						onChangeRole(community, role);
					}}
					user={user}
					community={community}
					//! コミュニティ自体を削除する処理を追加しても良い
				/>
				<MemberShowDialog
					open={openDialogs.memberShowDialog}
					onClose={() => setOpenDialogs({ ...openDialogs, memberShowDialog: false })}
					member={member}
				/>
				<EventDeleteDialog
					open={openDialogs.eventDeleteDialog}
					onClose={() => setOpenDialogs({ ...openDialogs, eventDeleteDialog: false })}
					onEnter={() => {
						if (!event || !onEventDelete) return;
						setOpenDialogs({
							...openDialogs,
							eventDeleteDialog: false,
							eventDialog: false,
						});
						onEventDelete(event);
					}}
				/>
				<EventDialog
					open={openDialogs.eventDialog}
					user={user}
					community={community}
					initialEvent={event}
					isUpdate={isEventUpdate}
					onClose={() => setOpenDialogs({ ...openDialogs, eventDialog: false })}
					onSubmit={(submittedEvent: EventInput) => {
						if (!onEventCreate && !onEventUpdate) return;
						setOpenDialogs({ ...openDialogs, eventDialog: false });
						if (isEventUpdate) {
							onEventUpdate && onEventUpdate(submittedEvent);
						} else {
							onEventCreate && onEventCreate(submittedEvent);
						}
					}}
					onDelete={() => {
						setOpenDialogs({ ...openDialogs, eventDeleteDialog: true });
					}}
				/>
				<LoginDialog
					open={openDialogs.loginDialog}
					// ログイン処理にはonCloseは必要ない
					onSubmit={(submittedUser: User) => {
						if (!onLogin) return;
						onLogin(submittedUser);
					}}
					onRegister={() => setOpenDialogs({ ...openDialogs, registerDialog: true })}
				/>
				<LogoutDialog
					open={openDialogs.logoutDialog}
					onClose={() => setOpenDialogs({ ...openDialogs, logoutDialog: false })}
					onEnter={() => {
						setOpenDialogs({ ...openDialogs, logoutDialog: false });
						onLogout && onLogout();
					}}
				/>
				<MoodAskDialog
					user={user}
					open={askMood}
					onSubmit={(mood: Mood) => {
						if (!onMoodCreate) return;
						onMoodCreate(mood);
					}}
				/>
				<NotificationListShowDialog
					open={openDialogs.notificationListShowDialog}
					onClose={() => setOpenDialogs({ ...openDialogs, notificationListShowDialog: false })}
					onClick={(notification: Notification) => {
						setNotification(notification);
						setOpenDialogs({ ...openDialogs, notificationShowDialog: true });
					}}
					notifications={notifications}
				/>
				<NotificationShowDialog
					open={openDialogs.notificationShowDialog}
					onClose={() => setOpenDialogs({ ...openDialogs, notificationShowDialog: false })}
					notification={notification}
				/>
				<UserUpdateDialog
					user={user}
					open={openDialogs.userUpdateDialog}
					onClose={() => setOpenDialogs({ ...openDialogs, userUpdateDialog: false })}
					onSubmit={(submittedUser: User) => {
						if (!onUpdateUser) return;
						setOpenDialogs({ ...openDialogs, userUpdateDialog: false });
						onUpdateUser(submittedUser);
					}}
				/>
				<RegisterDialog
					open={openDialogs.registerDialog}
					onClose={() => setOpenDialogs({ ...openDialogs, registerDialog: false })}
					onSubmit={(submittedUser: User) => {
						if (!onRegister) return;
						onRegister(submittedUser);
					}}
				/>
			</DivDialogs>
		);
	};

	return (
		<DivCommonsCalendarTemplate>
			{renderHeader()}
			<DivMiddle>
				<DivSwitchFlexbox open={openLeftSidebar}>{renderLeftSidebar()}</DivSwitchFlexbox>
				{renderMainCalendar()}
				{renderRightSidebar()}
			</DivMiddle>
			{renderDialogs()}
		</DivCommonsCalendarTemplate>
	);
};

const DivCommonsCalendarTemplate = styled.div``;

const DivHeader = styled.div``;

const DivMiddle = styled.div`
	display: flex;
`;

const DivLeftSidebar = styled.div``;

const DivMainCalendar = styled.div<{ width: string }>`
	width: ${(props) => props.width};
`;

const DivRightSidebar = styled.div``;

const DivDialogs = styled.div`
	display: flex;
`;
