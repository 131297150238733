import React from 'react';
import { styled } from 'styled-components';
import { Avatar } from '@mui/material';
import { CardHeader } from '@mui/material';
import { Insights } from '@mui/icons-material';
import { Card } from '@mui/material';
import { CardContent } from '@mui/material';

import { Advice } from '../../../types';

interface AdviceCardProps {
	advice?: Advice;
	width?: string;
	height?: string;
}

const defaultAdvice: Advice = {
	message: '良い1日を！',
};

export const AdviceCard: React.FC<AdviceCardProps> = ({
	advice = defaultAdvice,
	width = '300px',
	height = '100%',
}) => {
	return (
		<DivAdviceCard
			width={width}
			height={height}
		>
			<Card
				style={{
					width: '100%',
					height: '100%',
					backgroundColor: '#f0f4f8',
				}}
			>
				<CardHeader
					avatar={
						<Avatar>
							<Insights />
						</Avatar>
					}
					title="ChatGPTによる生活アドバイス"
				/>
				<CardContent>{advice.message}</CardContent>
			</Card>
		</DivAdviceCard>
	);
};

const DivAdviceCard = styled.div<{ width: string; height: string }>`
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	overflow-y: auto;
`;
