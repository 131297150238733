import React from 'react';
import { styled } from 'styled-components';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { DialogContentText } from '@mui/material';
import { Divider } from '@mui/material';

import { Member } from '../../../types';
import { BaseDialog } from '../../molecules/BaseDialog/BaseDialog';
import { CharIcon } from '../../atoms/CharIcon/CharIcon';
import { MoodIcon } from '../../atoms/MoodIcon/MoodIcon';


interface MemberShowDialogProps {
  open?: boolean;
  member?: Member;
  onClose?: () => void;
};

export const MemberShowDialog: React.FC<MemberShowDialogProps> = ({
  open,
  member,
  onClose,
}) => {

  const scoreToString = (score? : number) => {
    switch (score) {
      case 1:
        return 'すごく調子が悪いみたい...';
      case 2:
        return 'ちょっと調子が悪いみたい...';
      case 3:
        return '調子は普通';
      case 4:
        return '調子がいいみたい！';
      case 5:
        return 'すごく調子がいいみたい！';
      default:
        return '気分が未登録です';
    }
  }

  return (
    <DivMemberShowDialog>
      <BaseDialog
        open={open}
        width="xs"
        topActionChildren={
          <IconButton
            onClick={onClose}
          >
            <Close />
          </IconButton>
        }
        contentChildren={
          <DivContentChildren>
            <DivFlexColumn>
              <DivFlexRow>
                <CharIcon
                  char={member?.user.name}
                />
                <DialogContentText
                  sx = {{
                    fontWeight: 'bold',
                    fontSize: '1.5rem',
                    verticalAlign: 'center',
                    marginLeft: '1rem',
                  }}
                >
                  {member?.user.name}
                </DialogContentText>
              </DivFlexRow>
              <DivBlankBox />
              <DialogContentText
                sx = {{
                  wordBreak: 'break-word',
                }}
              >
                {member?.user.description}
              </DialogContentText>
              <DivBlankBox />
              <Divider />
              <DivBlankBox />
              <DivFlexRow>
                <MoodIcon
                  number={member?.mood.score}
                />
                <DialogContentText
                  sx = {{
                    marginLeft: '1rem',
                  }}
                >
                  {scoreToString(member?.mood.score)}
                </DialogContentText>
              </DivFlexRow>
              <DivBlankBox />
              <Divider />
              <DivBlankBox />
              <DialogContentText
                sx = {{
                  fontWeight: 'bold',
                }}
              >
                役割: {member?.role.name}
              </DialogContentText>
              <DivBlankBox />
              <DialogContentText
                sx = {{
                  marginLeft: '1rem',
                  wordBreak: 'break-word',
                }}
              >
                {member?.role.description}
              </DialogContentText>
            </DivFlexColumn>
          </DivContentChildren>
        }
        onClose={onClose}
        titleChildren={member?.user.name}
      />
    </DivMemberShowDialog>
  );
};

          //   <CharIcon
          //     char={member?.user.name}
          //   />
          //   <MoodIcon
          //     number={member?.mood.score}
          //   />
          //   <p>{member?.user.description}</p>
          //   <p>{member?.role.name}</p>
          //   <p>{member?.role.description}</p>
  
const DivMemberShowDialog = styled.div`
  display: flex;
`;

const DivFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const DivFlexRow = styled.div`
  display: flex;
  align-items: center;
`;

const DivBlankBox = styled.div`
  height: 1rem;
`;

const DivContentChildren = styled.div`
`;