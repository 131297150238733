/**
 * @file src/state/ducks/users.ts
 * @description ユーザーに関するRedux Duck
 * @module Ducks/Users
 * @category State
 * @require module:axios
 * @require module:@reduxjs/toolkit
 * @require module:../../types
 * @require module:../../config
 * @exports userState
 * @exports userSlice
 * @exports getUser
 * @exports postUser
 * @exports putUser
 * @exports setUser
 * @exports resetUser
 * @exports default
 */

import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { User } from '../../types';

import { api } from '../../config';

export interface userState {
	asyncStatus?: string;
	user?: User;
}

const initialState: userState = {
	asyncStatus: undefined,
	user: undefined,
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		/**
		 * ユーザー情報を一時的にセットする
		 * @param state
		 * @param action
		 */
		setUser: (state, action: PayloadAction<User>) => {
			state.user = action.payload;
		},
		/**
		 * ユーザー情報をリセットする
		 * @param state
		 */
		resetUser: (state) => {
			state.user = initialState.user;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(postUser.fulfilled, (state, action) => {
				state.user = action.payload;
				state.asyncStatus = 'FULFILLED_POST_USER';
			})
			.addCase(postUser.pending, (state, action) => {
				state.asyncStatus = 'PENDING_POST_USER';
			})
			.addCase(postUser.rejected, (state, action) => {
				state.user = initialState.user;
				state.asyncStatus = 'REJECTED_POST_USER';
			});
		builder
			.addCase(putUser.fulfilled, (state, action) => {
				state.user = action.payload;
				state.asyncStatus = 'FULFILLED_PUT_USER';
			})
			.addCase(putUser.pending, (state, action) => {
				state.asyncStatus = 'PENDING_PUT_USER';
			})
			.addCase(putUser.rejected, (state, action) => {
				state.asyncStatus = 'REJECTED_PUT_USER';
			});
		builder
			.addCase(getUser.fulfilled, (state, action) => {
				state.user = action.payload;
				state.asyncStatus = 'FULFILLED_GET_USER';
			})
			.addCase(getUser.pending, (state, action) => {
				state.asyncStatus = 'PENDING_GET_USER';
			})
			.addCase(getUser.rejected, (state, action) => {
				state.user = initialState.user;
				state.asyncStatus = 'REJECTED_GET_USER';
			});
	},
});

/**
 * ユーザー情報を取得する非同期関数
 *
 * @param {string} userId - ユーザーID
 * @returns {Promise<User>} ユーザー情報
 * @example
 * dispatch(getUser(userId));
 */
export const getUser = createAsyncThunk('user/getUser', async (userId: string) => {
	const res = await axios.get(`${api}/users/${userId}`);
	return res.data;
});

/**
 * ユーザー情報を登録する非同期関数
 * @param {User} args.user - ユーザー情報
 * @returns {Promise<User>} ユーザー情報
 * @example
 * dispatch(postUser(user));
 */
export const postUser = createAsyncThunk('user/postUser', async (user: User) => {
	const res = await axios.post(`${api}/users`, user);
	return res.data;
});

/**
 * ユーザー情報を更新する非同期関数
 * @param {User} args.user - ユーザー情報
 * @returns {Promise<User>} ユーザー情報
 * @example
 * dispatch(putUser(user));
 */
export const putUser = createAsyncThunk('user/putUser', async (user: User) => {
	const userId = user.id;
	delete user.id;
	const res = await axios.put(`${api}/users/${userId}`, user);
	return res.data;
});

export const { setUser, resetUser } = userSlice.actions;

export default userSlice.reducer;
