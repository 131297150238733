import React from 'react';
import { styled } from 'styled-components';
import { Button } from '@mui/material';
import { DialogContentText } from '@mui/material';

import { BaseDialog } from '../../molecules/BaseDialog/BaseDialog';

interface EventDeleteDialogProps {
  open?: boolean;
  onClose?: () => void;
  onEnter?: () => void;
};

export const EventDeleteDialog: React.FC<EventDeleteDialogProps> = ({
  open,
  onClose,
  onEnter,
}) => {
  return (
    <DivEventDeleteDialog>
      <BaseDialog
        open={open}
        width="xs"
        bottomActionChildren={
          <DivContentChildren>
            <Button
              onClick={onClose}
            >
              キャンセル
            </Button>
            <Button
              onClick={onEnter}
              color="warning"
              variant="contained"
            >
              削除
            </Button>
          </DivContentChildren>
        }
        contentChildren={
          <DialogContentText>
            本当にこの予定を削除しますか？
          </DialogContentText>
        }
        onClose={onClose}
        titleChildren="予定を削除する"
      />
    </DivEventDeleteDialog>
  );
};

const DivEventDeleteDialog = styled.div`
  display: 'flex';
`;

const DivContentChildren = styled.div`
`;