import React from 'react';
import { styled } from 'styled-components';

import { SingleCheckbox } from '../../atoms/SingleCheckbox/SingleCheckbox';

interface RowCheckboxesProps {
  values?: number[];
  labels?: string[];
  onChange?: (values: number[]) => void;
};

export const RowCheckboxes: React.FC<RowCheckboxesProps> = ({
  values,
  labels,
  onChange,
}) => {
  const checkboxes = labels?.map((label, index) => {
    return (
      <SingleCheckbox
        key={index}
        label={label}
        checked={values?.includes(index)}
        onClick={() => {
          if (values) {
            if (values.includes(index)) {
              onChange && onChange(values.filter((value) => value !== index));
            } else {
              onChange && onChange([...values, index]);
            }
          } else {
            onChange && onChange([index]);
          }
        }}
      />
    );
  });

  return (
    <DivRowCheckboxes>
      {checkboxes}
    </DivRowCheckboxes>
  );
};

const DivRowCheckboxes = styled.div`
  display: flex;
  flex-deirection: row;
`;
