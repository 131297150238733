import React from 'react';
import { styled } from 'styled-components';
import { Menu } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';
import { Create } from '@mui/icons-material';
import { Campaign } from '@mui/icons-material';
import { Logout } from '@mui/icons-material';
import { ToggleButtonGroup } from '@mui/material';
import { ToggleButton } from '@mui/material';
import { Avatar } from '@mui/material';
import { MenuItem } from '@mui/material';
import { ListItemIcon } from '@mui/material';
import { ListItemText } from '@mui/material';
import { Badge } from '@mui/material';
import Diversity3Icon from '@mui/icons-material/Diversity3';

import { Community, Notification, User } from '../../../types';
import { BaseMenu } from '../../molecules/BaseMenu/BaseMenu';


interface HeaderProps {
  communities?: Community[];
  notifications?: Notification[];
  user?: User;
  view?: string;
  height?: string;
  onClickMenu?: () => void;
  onChangeView?: (view: string) => void;
  onClickNotification?: () => void;
  onClickCommunity?: (community: Community) => void;
  onClickCreateCommunity?: () => void;
  onClickJoinCommunity?: () => void;
  onClickProfile?: () => void;
  onClickLogout?: () => void;
};

export const Header: React.FC<HeaderProps> = ({
  communities,
  notifications,
  user,
  view,
  height = "3rem",
  onClickMenu,
  onChangeView,
  onClickNotification,
  onClickCommunity,
  onClickCreateCommunity,
  onClickJoinCommunity,
  onClickProfile,
  onClickLogout,
}) => {

  const renderCommunities = (communities?: Community[]) => {
    return communities?.map((community) => {
      return (
        <MenuItem onClick={() => onClickCommunity && onClickCommunity(community)}>
          <ListItemIcon>
            <Avatar>{community.name.slice(0, 1)}</Avatar>
          </ListItemIcon>
          <ListItemText
            sx = {{
              marginLeft: '0.5rem',
            }}
          >
            {community.name}
          </ListItemText>
        </MenuItem>
      );
    });
  }

  return (
    <DivHeader height={height}>
      <DivLeft>
        <DivMenu>
          <IconButton onClick={onClickMenu}>
            <Menu />
          </IconButton>
        </DivMenu>
        <DivTitle>
          {"Commons Calendar"}
        </DivTitle>
      </DivLeft>
      <DivCenter>
        <DivViewToggleButton>
          <ToggleButtonGroup
            value={view}
            exclusive={true}
            onChange={(e: React.MouseEvent<HTMLElement>, view: string) => {
              view && onChangeView && onChangeView(view);
            }}
          >
            <ToggleButton value="dayGridMonth">{"月"}</ToggleButton>
            <ToggleButton value="timeGridWeek">{"週"}</ToggleButton>
            <ToggleButton value="timeGridDay">{"日"}</ToggleButton>
          </ToggleButtonGroup>
        </DivViewToggleButton>
      </DivCenter>
      <DivRight>
        <DivNotifications>
          <IconButton onClick={onClickNotification}>
            <Badge badgeContent={notifications?.length}>
              <Campaign />
            </Badge>
          </IconButton>
        </DivNotifications>
        <DivCommunityMenu>
          <BaseMenu
            isIconButton={true}
            ButtonContentChildren={
              <Diversity3Icon />
            }
            MenuItemChildren={
              <DivMenuItems>
                {renderCommunities(communities)}
                <MenuItem onClick={onClickJoinCommunity}>
                  <ListItemIcon>
                    <Add />
                  </ListItemIcon>
                  <ListItemText>
                    {"コミュニティに参加"}
                  </ListItemText>
                </MenuItem>
                <MenuItem onClick={onClickCreateCommunity}>
                  <ListItemIcon>
                    <Create />
                  </ListItemIcon>
                  <ListItemText>
                    {"コミュニティを作成"}
                  </ListItemText>
                </MenuItem>
              </DivMenuItems>
            }
          />
        </DivCommunityMenu>
        <DivAccountMenu>
          <BaseMenu
            isIconButton={true}
            ButtonContentChildren={
              <Avatar>{user?.name.slice(0, 1)}</Avatar>
            }
            MenuItemChildren={
              <DivMenuItems>
                <MenuItem onClick={onClickProfile}>
                  <ListItemIcon>
                    <Avatar>{user?.name.slice(0, 1)}</Avatar>
                  </ListItemIcon>
                  <ListItemText
                    sx = {{
                      marginLeft: '0.5rem',
                    }}
                  >
                    {"プロフィール"}
                  </ListItemText>
                </MenuItem>
                <MenuItem onClick={onClickLogout}>
                  <ListItemIcon>
                    <Logout />
                  </ListItemIcon>
                  <ListItemText>
                    {"ログアウト"}
                  </ListItemText>
                </MenuItem>
              </DivMenuItems>
            }
          />
        </DivAccountMenu>
      </DivRight>
    </DivHeader>
  );
};

const DivHeader = styled.div< { height: string } >`
  height: ${(props) => props.height};
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const DivLeft = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px
`;

const DivMenu = styled.div`
  display: flex;
`;

const DivTitle = styled.div`
  margin: 0 20px;
  display: flex;
  text-weight: bold;
`;

const DivCenter = styled.div`
  display: flex;
  align-items: center;
`;

const DivViewToggleButton = styled.div`
  display: flex;
  height: 30px;
`;

const DivRight = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const DivNotifications = styled.div`
  display: flex;
  margin-right: 5px;
`;

const DivCommunityMenu = styled.div`
  display: flex;
  margin-right: 5px;
`;

const DivMenuItems = styled.div`
  display: flex;
  flex-direction: column;
`;

const DivAccountMenu = styled.div`
  display: flex;
`;
