/**
 * @file src/state/ducks/communities.ts
 * @description コミュニティに関するRedux Duck
 * @module Ducks/Communities
 * @category State
 * @require module:axios
 * @require module:@reduxjs/toolkit
 * @require module:uuid
 * @require module:../../types
 * @require module:../../config
 * @exports communitiesState
 * @exports communitiesSlice
 * @exports getCommunities
 * @exports postCommunity
 * @exports putCommunity
 * @exports deleteCommunity
 * @exports joinCommunity
 * @exports putRole
 * @exports leaveCommunity
 * @exports default
 */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { Community, User, Role } from '../../types';
import { api } from '../../config';

export interface communitiesState {
	asyncStatus?: string;
	communities?: Community[];
}

const initialState: communitiesState = {
	asyncStatus: undefined,
	communities: [],
};

export const communitiesSlice = createSlice({
	name: 'communities',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getCommunities.fulfilled, (state, action) => {
				state.communities = action.payload;
				state.asyncStatus = 'FULFILLED_GET_COMMUNITIES';
			})
			.addCase(getCommunities.pending, (state, action) => {
				state.asyncStatus = 'PENDING_GET_COMMUNITIES';
			})
			.addCase(getCommunities.rejected, (state, action) => {
				state.asyncStatus = 'REJECTED_GET_COMMUNITIES';
			});

		builder
			.addCase(postCommunity.fulfilled, (state, action) => {
				state.communities = action.payload;
				state.asyncStatus = 'FULFILLED_POST_COMMUNITY';
			})
			.addCase(postCommunity.pending, (state, action) => {
				state.asyncStatus = 'PENDING_POST_COMMUNITY';
			})
			.addCase(postCommunity.rejected, (state, action) => {
				state.asyncStatus = 'REJECTED_POST_COMMUNITY';
			});

		builder
			.addCase(putCommunity.fulfilled, (state, action) => {
				state.communities = action.payload;
				state.asyncStatus = 'FULFILLED_PUT_COMMUNITY';
			})
			.addCase(putCommunity.pending, (state, action) => {
				state.asyncStatus = 'PENDING_PUT_COMMUNITY';
			})
			.addCase(putCommunity.rejected, (state, action) => {
				state.asyncStatus = 'REJECTED_PUT_COMMUNITY';
			});

		builder
			.addCase(deleteCommunity.fulfilled, (state, action) => {
				state.communities = action.payload;
				state.asyncStatus = 'FULFILLED_DELETE_COMMUNITY';
			})
			.addCase(deleteCommunity.pending, (state, action) => {
				state.asyncStatus = 'PENDING_DELETE_COMMUNITY';
			})
			.addCase(deleteCommunity.rejected, (state, action) => {
				state.asyncStatus = 'REJECTED_DELETE_COMMUNITY';
			});

		builder
			.addCase(joinCommunity.fulfilled, (state, action) => {
				state.communities = action.payload;
				state.asyncStatus = 'FULFILLED_JOIN_COMMUNITY';
			})
			.addCase(joinCommunity.pending, (state, action) => {
				state.asyncStatus = 'PENDING_JOIN_COMMUNITY';
			})
			.addCase(joinCommunity.rejected, (state, action) => {
				state.asyncStatus = 'REJECTED_JOIN_COMMUNITY';
			});

		builder
			.addCase(putRole.fulfilled, (state, action) => {
				state.communities = action.payload;
				state.asyncStatus = 'FULFILLED_PUT_ROLE';
			})
			.addCase(putRole.pending, (state, action) => {
				state.asyncStatus = 'PENDING_PUT_ROLE';
			})
			.addCase(putRole.rejected, (state, action) => {
				state.asyncStatus = 'REJECTED_PUT_ROLE';
			});

		builder
			.addCase(leaveCommunity.fulfilled, (state, action) => {
				state.communities = action.payload;
				state.asyncStatus = 'FULFILLED_LEAVE_COMMUNITY';
			})
			.addCase(leaveCommunity.pending, (state, action) => {
				state.asyncStatus = 'PENDING_LEAVE_COMMUNITY';
			})
			.addCase(leaveCommunity.rejected, (state, action) => {
				state.asyncStatus = 'REJECTED_LEAVE_COMMUNITY';
			});
	},
});

/**
 * ユーザーIDを元にコミュニティ情報を取得する非同期関数
 *
 * @param {string} userId - ユーザーID
 * @returns {Promise<Community[]>} コミュニティ情報
 * @example
 * dispatch(getCommunities(userId));
 */
export const getCommunities = createAsyncThunk(
	'communities/getCommunities',
	async (userId: string) => {
		const res = await axios.get(`${api}/communities/${userId}`);
		return res.data;
	}
);

/**
 * コミュニティを新規作成する非同期関数
 *
 * @param {User} args.user - ユーザー情報
 * @param {Community} args.community - コミュニティ情報
 * @param {Role} args.role - ロール情報
 * @returns {Promise<Community[]>} コミュニティ情報
 * @example
 * dispatch(postCommunity({ user, community, role }));
 * @remarks
 * ユーザーIDが存在しない場合は処理を行わない
 */
export const postCommunity = createAsyncThunk(
	'communities/postCommunity',
	async (args: { user: User; community: Community; role: Role }) => {
		if (!args.user.id) {
			return;
		}
		let community = await axios.post(`${api}/communities`, {
			name: args.community.name,
			description: args.community.description,
		});

		await axios.post(`${api}/roles`, {
      userId: args.user.id,
			communityId: community.data.id,
			name: args.role.name,
			description: args.role.description,
		});
		const res = await axios.get(`${api}/communities/${args.user.id}`);
		return res.data;
	}
);

/**
 * コミュニティ情報を更新する非同期関数
 *
 * @param {User} args.user - ユーザー情報
 * @param {Community} args.community - コミュニティ情報
 * @returns {Promise<Community[]>} コミュニティ情報
 * @example
 * dispatch(putCommunity({ user, community }));
 */
export const putCommunity = createAsyncThunk(
	'communities/putCommunity',
	async (args: { user: User; community: Community }) => {
		await axios.put(`${api}/communities/${args.community.id}`, {
			name: args.community.name,
			description: args.community.description,
		});
		const res = await axios.get(`${api}/communities/${args.user.id}`);
		return res.data;
	}
);

/**
 * コミュニティを削除する非同期関数
 * @param {User} args.user - ユーザー情報
 * @param {Community} args.community - コミュニティ情報
 * @returns {Promise<Community[]>} コミュニティ情報
 * @example
 * dispatch(deleteCommunity({ user, community }));
 * @remarks
 * 現時点ではフロントの構成要素として実装されていない
 */
export const deleteCommunity = createAsyncThunk(
	'communities/deleteCommunity',
	async (args: { user: User; community: Community }) => {
		await axios.delete(`${api}/communities/${args.community.id}`);
		leaveCommunity({ user: args.user, community: args.community });
		const res = await axios.get(`${api}/communities/${args.user.id}`);
		return res.data;
	}
);

/**
 * コミュニティに参加する非同期関数
 * @param {User} args.user - ユーザー情報
 * @param {Community} args.community - コミュニティ情報
 * @param {Role} args.role - ロール情報
 * @returns {Promise<Community[]>} コミュニティ情報
 * @example
 * dispatch(joinCommunity({ user, community, role }));
 * @remarks
 * ユーザーIDとコミュニティIDが存在しない場合は処理を行わない
 */
export const joinCommunity = createAsyncThunk(
	'communities/JoinCommunity',
	async (args: { user: User; community: Community; role: Role }) => {
		if (!args.user.id || !args.community.id) {
			return;
		}
		const role: Role = {
			// id: uuidv4(),
			userId: args.user.id,
			communityId: args.community.id,
			name: args.role.name,
			description: args.role.description,
		};
		await axios.post(`${api}/roles`, role);
		const res = await axios.get(`${api}/communities/${args.user.id}`);
		return res.data;
	}
);

/**
 * ユーザーの役割情報を更新する非同期関数
 *
 * @param {User} args.user - ユーザー情報
 * @param {Community} args.community - コミュニティ情報
 * @param {Role} args.role - ロール情報
 * @returns {Promise<Community[]>} コミュニティ情報
 * @example
 * dispatch(putRole({ user, community, role }));
 */
export const putRole = createAsyncThunk(
	'communities/putRole',
	async (args: { user: User; community: Community; role: Role }) => {
		await axios.put(`${api}/roles/${args.role.id}`, {
			userId: args.role.userId,
			communityId: args.role.communityId,
			name: args.role.name,
			description: args.role.description,
		});
		const res = await axios.get(`${api}/communities/${args.user.id}`);
		return res.data;
	}
);

/**
 * コミュニティから脱退する非同期関数
 *
 * @param {User} args.user - ユーザー情報
 * @param {Community} args.community - コミュニティ情報
 * @returns {Promise<Community[]>} コミュニティ情報
 * @example
 * dispatch(leaveCommunity({ user, community }));
 * @remarks
 */
export const leaveCommunity = createAsyncThunk(
	'communities/leaveCommunity',
	async (args: { user: User; community: Community }) => {
		await axios.delete(`${api}/roles?user_id=${args.user.id}&community_id=${args.community.id}`);
		const res = await axios.get(`${api}/communities/${args.user.id}`);
		return res.data;
	}
);

export default communitiesSlice.reducer;
