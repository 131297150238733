import React from 'react';
import { styled } from 'styled-components';
import { CirclePicker, ColorResult } from 'react-color';

interface ColorPickerProps {
  color?: string;
  width?: string;
  colors?: string[];
  onChange?: (color: string) => void;
};

const defaultColors = [
  "#F38D8D",
  "#F59E7D",
  "#F7AE6C",
  "#FACF4A",
  "#BED3A0",
  "#A0D5CD",
  "#82D6F6",
]

export const ColorPicker: React.FC<ColorPickerProps> = ({
  color = defaultColors[0],
  colors = defaultColors,
  width = "24rem",
  onChange,
}) => {
  return (
    <DivColorPicker>
      <CirclePicker
        color={color}
        width={width}
        colors={colors}
        onChange={(color: ColorResult) => onChange && onChange(color.hex)}
      />
    </DivColorPicker>
  );
};

const DivColorPicker = styled.div`
`;