import React from 'react';
import { styled } from 'styled-components';
import { Button } from '@mui/material';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

import { User } from '../../../types';
import { BaseDialog } from '../../molecules/BaseDialog/BaseDialog';
import { SimpleTextField } from '../../atoms/SimpleTextField/SimpleTextField';

interface RegisterDialogProps {
  open?: boolean;
  initialUser?: User;
  onClose?: () => void;
  onSubmit?: (user: User) => void;
};

const defaultUser: User = {
  id: '',
  name: '',
  description: '',
};

export const RegisterDialog: React.FC<RegisterDialogProps> = ({
  open,
  initialUser = defaultUser,
  onClose,
  onSubmit,
}) => {

  const [user, setUser] = React.useState<User>(initialUser);

  return (
    <DivRegisterDialog>
      <BaseDialog
        open={open}
        width="xs"
        topActionChildren={
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        }
        contentChildren={
          <DivContentChildren>
            <SimpleTextField
              label="ID"
              value={user.id}
              onChange={(userId: string) => {
                setUser({ ...user, id: userId });
              }}
              helperText='※必須 5~36文字の半角英数字'
            />
            <SimpleTextField
              label="名前"
              value={user.name}
              onChange={(userName: string) => {
                setUser({ ...user, name: userName });
              }}
              helperText='※必須 1文字以上の文字列'
            />
            <SimpleTextField
              label="自己紹介"
              value={user.description}
              onChange={(userDescription: string) => {
                setUser({ ...user, description: userDescription });
              }}
              helperText='任意'
            />
          </DivContentChildren>
        }
        bottomActionChildren={
          <Button
            onClick={() => {
              onSubmit && onSubmit(user);
            }}
          >
            新規登録
          </Button>
        }
        titleChildren="ユーザーの新規登録"
      />
    </DivRegisterDialog>
  );
};

const DivRegisterDialog = styled.div`
  display: 'flex';
`;

const DivContentChildren = styled.div`
`;