import React, { useEffect } from 'react';
import { styled } from 'styled-components';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Button } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { DialogContentText } from '@mui/material';
import { Divider } from '@mui/material';

import { Community, Role } from '../../../types';
import { BaseDialog } from '../../molecules/BaseDialog/BaseDialog';
import { SimpleTextField } from '../../atoms/SimpleTextField/SimpleTextField';

interface CommunityCreateDialogProps {
	open?: boolean;
	initialCommunity?: Community;
	initialRole?: Role;
	onClose?: () => void;
	onSubmit?: (community: Community, role: Role) => void;
}

const defaultCommunity: Community = {
	id: '',
	name: '',
	description: '',
	members: [],
};

const defaultRole: Role = {
	id: '',
	userId: '',
	communityId: '',
	name: '',
	description: '',
};

export const CommunityCreateDialog: React.FC<CommunityCreateDialogProps> = ({
	open,
	initialCommunity = defaultCommunity,
	initialRole = defaultRole,
	onClose,
	onSubmit,
}) => {
	const [community, setCommunity] = React.useState<Community>(initialCommunity);
	const [role, setRole] = React.useState<Role>(initialRole);

	useEffect(() => {
		setCommunity(initialCommunity);
		setRole(initialRole);
	}, [initialCommunity, initialRole]);

	return (
		<DivCommunityCreateDialog>
			<BaseDialog
				open={open}
				width="xs"
				bottomActionChildren={
					<Button
						onClick={() => {
							let newCommunity = { ...community };
							newCommunity.id = uuidv4();
							onSubmit && onSubmit(newCommunity, role);
						}}
					>
						作成
					</Button>
				}
				contentChildren={
					<DivContentChildren>
						<DialogContentText
							sx={{
								fontWeight: 'bold',
							}}
						>
							コミュニティについて教えてください。
						</DialogContentText>
						<SimpleTextField
							label="コミュニティ名"
							value={community.name}
							width={'12rem'}
							helperText="1文字以上の文字列"
							onChange={(communityName: string) => {
								setCommunity({ ...community, name: communityName });
							}}
						/>
						<SimpleTextField
							label="コミュニティの説明文"
							value={community.description}
							multiline={true}
							onChange={(communityDescription: string) => {
								setCommunity({ ...community, description: communityDescription });
							}}
						/>
						<Divider />
						<DivBlankBox />
						<DialogContentText
							sx={{
								fontWeight: 'bold',
							}}
						>
							コミュニティの中でのあなたの役割について教えてください。
						</DialogContentText>
						<SimpleTextField
							label="あなたの役割"
							value={role.name}
							width={'12rem'}
							onChange={(roleName: string) => {
								setRole({ ...role, name: roleName });
							}}
						/>
						<SimpleTextField
							label="役割の説明"
							value={role.description}
							multiline={true}
							helperText="1文字以上の文字列"
							onChange={(roleDescription: string) => {
								setRole({ ...role, description: roleDescription });
							}}
						/>
					</DivContentChildren>
				}
				onClose={onClose}
				titleChildren="新しいコミュニティを作成する"
				topActionChildren={
					<IconButton onClick={onClose}>
						<Close />
					</IconButton>
				}
			/>
		</DivCommunityCreateDialog>
	);
};

const DivCommunityCreateDialog = styled.div`
	display: 'flex';
`;

const DivContentChildren = styled.div`
	display: 'flex';
`;

const DivBlankBox = styled.div`
	height: 1rem;
`;
