import React from 'react';
import { styled } from 'styled-components';
import { Button } from '@mui/material';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

import { User } from '../../../types';
import { BaseDialog } from '../../molecules/BaseDialog/BaseDialog';
import { SimpleTextField } from '../../atoms/SimpleTextField/SimpleTextField';

interface UserUpdateDialogProps {
	open?: boolean;
	initialUser?: User;
	user?: User;
	onClose?: () => void;
	onSubmit?: (user: User) => void;
}

const defaultUser: User = {
	id: '',
	name: '',
	description: '',
};

export const UserUpdateDialog: React.FC<UserUpdateDialogProps> = ({
	user,
	open,
	onClose,
	onSubmit,
}) => {
	const [tmpUser, setUser] = React.useState<User>(defaultUser);

	return (
		<DivUserUpdateDialog>
			<BaseDialog
				open={open}
				width="xs"
				topActionChildren={
					<IconButton onClick={onClose}>
						<Close />
					</IconButton>
				}
				contentChildren={
					<DivContentChildren>
						<SimpleTextField
							label="名前"
							value={tmpUser.name}
							onChange={(userName: string) => {
								setUser({ ...tmpUser, name: userName });
							}}
							helperText="※必須 1文字以上の文字列"
						/>
						<SimpleTextField
							label="自己紹介"
							value={tmpUser.description}
							onChange={(userDescription: string) => {
								setUser({ ...tmpUser, description: userDescription });
							}}
							helperText="任意"
						/>
					</DivContentChildren>
				}
				bottomActionChildren={
					<Button
						onClick={() => {
							onSubmit && onSubmit({ ...tmpUser, id: user?.id || '' });
						}}
					>
						更新
					</Button>
				}
				titleChildren="ユーザー情報の更新"
			/>
		</DivUserUpdateDialog>
	);
};

const DivUserUpdateDialog = styled.div`
	display: 'flex';
`;

const DivContentChildren = styled.div``;
