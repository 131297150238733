import React from 'react';
import { styled } from 'styled-components';
import { FormControlLabel } from '@mui/material';
import { Checkbox } from '@mui/material';

interface SingleCheckboxProps {
  label?: string;
  checked?: boolean;
  onClick?: () => void;
};

export const SingleCheckbox: React.FC<SingleCheckboxProps> = ({
  label,
  checked,
  onClick,
}) => {
  return (
    <DivSingleCheckbox>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onClick={onClick}
          />
        }
        label={label}
      />
    </DivSingleCheckbox>
  );
};

const DivSingleCheckbox = styled.div`
  display: 'flex';
`;