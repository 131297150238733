import React from 'react';
import { styled } from 'styled-components';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { CardHeader } from '@mui/material';

import { Notification } from '../../../types';
import { BaseDialog } from '../../molecules/BaseDialog/BaseDialog';
import { BaseCard } from '../../molecules/BaseCard/BaseCard';


interface NotificationListShowDialogProps {
  open?: boolean;
  notifications?: Notification[];
  onClose?: () => void;
  onClick?: (notification: Notification) => void;
};

export const NotificationListShowDialog: React.FC<NotificationListShowDialogProps> = ({
  open,
  notifications = [],
  onClose,
  onClick,
}) => {

  const notificationCards = notifications.map((notification) => {
    return (
      <BaseCard
        key={notification.id}
        onClick={() => onClick && onClick(notification)}
        headerChildren={
          <CardHeader
            title={notification.title}
            subheader={notification.description}
          />
        }
      />
    );
  });

  return (
    <DivNotificationListShowDialog>
      <BaseDialog
        open={open}
        width="xs"
        contentChildren={notificationCards}
        onClose={onClose}
        titleChildren="通知"
        topActionChildren={
          <IconButton
            onClick={onClose}
          >
            <Close />
          </IconButton>
        }
      />
    </DivNotificationListShowDialog>
  );
};

const DivNotificationListShowDialog = styled.div`
  display: 'flex';
`;
